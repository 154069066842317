import * as Axios from 'axios';

import { ArticlelistDetailItem } from '../providers/Models';

export class OrderlistProvider {

  async getArticlelistDetails(username: string, sessionKey: string): Promise<ArticlelistDetailItem[]> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/articlelistdetail?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'GET',
      headers: { Accept: 'application/json; odata=verbose' }
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }
}
