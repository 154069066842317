import React from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import * as Rx from "rxjs";

import { OrderDetailItem } from '../../providers/Models';

interface Props {
  okHandler: () => void,
  cancelHandler: () => void,
  show: boolean,
  subject: Rx.BehaviorSubject<OrderDetailItem>
}

interface State {
  menge: number,
  isMengeValid: boolean,
  portionierung: boolean,
  portionierungMenge: number,
  isPortionierungMengeValid: boolean,
  bemerkung: string,
  einheit: string,
}

export class OrderDetailModal extends React.Component<Props, State> {
  mengeInput: React.RefObject<HTMLInputElement>;
  portionierungMengeInput: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = { menge: 0, isMengeValid: true, portionierung: false, portionierungMenge: 0, isPortionierungMengeValid: true, bemerkung: '', einheit: '' };
    this.mengeInput = React.createRef();
    this.portionierungMengeInput = React.createRef();
  }

  componentDidMount() {
    this.props.subject.subscribe((data) => {
      this.setState({ menge: data.amount, portionierung: data.article.articlePortioning, portionierungMenge: data.portioningAmount, bemerkung: data.comment, einheit: data.unit });
    });
  }

  componentWillUnmount() {
    this.props.subject.unsubscribe();
  }

  handleMengeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = Number(event.currentTarget.value);
    if (isNaN(value) || value <= 0) {
      this.setState({ isMengeValid: false });
    }
    else {
      this.setState({ menge: value, isMengeValid: true });
    }
  }

  handlePortionierungMengeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = Number(event.currentTarget.value);
    if (isNaN(value) || value <= 0) {
      this.setState({ isPortionierungMengeValid: false });
    }
    else {
      this.setState({ portionierungMenge: value, isPortionierungMengeValid: true });
    }
  }

  handleBemerkungChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ bemerkung: event.currentTarget.value });
  }

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!this.state.isMengeValid) {
      return false;
    }

    const orderDetail = this.props.subject.value;
    orderDetail.amount = this.state.menge;
    orderDetail.portioningAmount = this.state.portionierungMenge;
    orderDetail.comment = this.state.bemerkung;

    this.props.subject.next(orderDetail);
    this.props.okHandler();
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={() => this.props.cancelHandler()} onShow={(): void => { this.mengeInput.current?.focus(); this.mengeInput.current?.select(); }} animation={false} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>Artikel bearbeiten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Label>Menge</Form.Label>
              <InputGroup>
                <Form.Control className={!this.state.isMengeValid ? "is-invalid" : ""} defaultValue={this.state.menge} onChange={this.handleMengeChange} ref={this.mengeInput} />
                <InputGroup.Append>
                  <InputGroup.Text>{this.state.einheit}</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              {
                this.state.portionierung && <React.Fragment>
                  <Form.Label className="mt-2">Portionsgrösse</Form.Label>
                  <Form.Control className={!this.state.isPortionierungMengeValid ? "is-invalid" : ""} defaultValue={this.state.portionierungMenge} onChange={this.handlePortionierungMengeChange} ref={this.portionierungMengeInput} />
                </React.Fragment>
              }
              <Form.Label className="mt-2">Bemerkung</Form.Label>
              <Form.Control as="textarea" rows={4} defaultValue={this.state.bemerkung} onChange={this.handleBemerkungChange} />
            </Form.Group>
            <Button variant="primary" type="submit">&Uuml;bernehmen</Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}
