import React from 'react';
import * as Constants from '../Constants';

export const Logout: React.FC<{}> = (props) => {
  sessionStorage.removeItem(Constants.Username);
  sessionStorage.removeItem(Constants.SessionKey);
  sessionStorage.removeItem(Constants.IsSessionValid);
  sessionStorage.removeItem(Constants.SelectedOrderId);
  sessionStorage.removeItem(Constants.Customer);
  
  return (
    <div>
      <p>Sie haben sich erfolgreich abgemeldet.</p>
    </div>
  );
}
