import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import * as Rx from "rxjs";

import { PromotionItem } from '../../providers/Models';

interface Props {
  subject: Rx.BehaviorSubject<PromotionItem>
}

interface State {
  promotion: PromotionItem,
  columns: any[]
}

export class PromotionArticleList extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { promotion: new PromotionItem(), columns: [{ dataField: "", text: "" }] };
  }

  componentDidMount() {
    this.props.subject.subscribe((data) => {
      this.setState({
        promotion: data,
        columns: data.customer.showArticlePrice ?
          [
            { dataField: 'article.no', text: 'Nr' },
            { dataField: 'article.name', text: 'Name' },
            { dataField: 'price', text: 'Preis', align: 'right', formatter: this.priceFormatter },
            { dataField: 'article.unit', text: 'Einheit' },
          ]
          : [
            { dataField: 'article.no', text: 'Nr' },
            { dataField: 'article.name', text: 'Name' },
            { dataField: 'article.unit', text: 'Einheit' },
          ]
      });
    });
  }

  componentWillUnmount() {
    this.props.subject.unsubscribe();
  }

  priceFormatter = (cell: any, _row: any, _rowIndex: any, _extra: any) => {
    return (
      <React.Fragment>
        { isNaN(cell) ? "" : cell.toFixed(2)}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ minHeight: "46px" }}>
          <h3>Artikel in Aktion</h3>
        </div>
        <BootstrapTable
          hover
          classes="table-sm w-auto"
          keyField='id'
          data={this.state.promotion.promotionArticles}
          columns={this.state.columns}
          pagination={paginationFactory({ sizePerPage: 5, hidePageListOnlyOnePage: true, hideSizePerPage: true })}
        />
      </React.Fragment>
    );
  }
}
