import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import moment from 'moment';

import { DocumentItem } from '../../providers/Models';

import * as Constants from '../Constants';

interface Props {
  documents: DocumentItem[],
}

interface State {
  username: string,
  sessionKey: string,
}

export class DocumentList extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      username: sessionStorage.getItem(Constants.Username) || '',
      sessionKey: sessionStorage.getItem(Constants.SessionKey) || '',
    };
  }

  linkFormatter = (_cell: any, row: DocumentItem) => {
    return (
      <a href={
        `${process.env.REACT_APP_API_URL}/document/${row.id}` +
        `?username=${this.state.username}&sessionkey=${this.state.sessionKey}`} >
        {row.filename}
      </a>
    );
  }

  dateFormatter = (cell: any, _row: any) => {
    return (
      <React.Fragment>
        { !!cell ? moment(cell).format('DD.MM.YYYY') : ""}
      </React.Fragment>
    );
  }

  sizeFormatter = (_cell: any, row: DocumentItem) => {
    return (
      <React.Fragment>
        { `${Math.round(row.filesize / 1024)} KB`}
      </React.Fragment>
    );
  }

  columns = [
    { dataField: 'filename', text: 'Dateiname', formatter: this.linkFormatter },
    { dataField: 'filedate', text: 'Datum', formatter: this.dateFormatter },
    { dataField: 'filesize', text: 'Grösse', formatter: this.sizeFormatter },
  ];

  render() {

    return (
      <BootstrapTable
        hover
        classes="table-sm w-auto"
        keyField='id'
        data={this.props.documents}
        columns={this.columns}
        pagination={paginationFactory({ sizePerPage: 5, hidePageListOnlyOnePage: true, hideSizePerPage: true })} />
    );
  }
}
