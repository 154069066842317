import React from 'react';
import { Container } from 'react-bootstrap';

import * as Rx from "rxjs";

import { NavMenu } from './NavMenu';

export const Layout: React.FC<{ children: JSX.Element[], eventSubject: Rx.BehaviorSubject<Date> }> = (props) => {
  return (
    <div>
      <NavMenu eventSubject={props.eventSubject} />
      <Container>
        {props.children}
      </Container>
    </div>
  );
}
