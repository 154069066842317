import React from 'react';
import { Redirect } from 'react-router';
import { Col, Container, Modal, Row, Spinner } from 'react-bootstrap';

import * as Rx from "rxjs";

import { SessionProvider } from '../../providers/SessionProvider';
import { CustomerProvider } from '../../providers/CustomerProvider';
import { OrderProvider } from '../../providers/OrderProvider';
import { ArticleProvider } from '../../providers/ArticleProvider';

import { ArticleItem, CustomerItem, OrderItem, OrderDetailItem, ArticlePhotoItem } from '../../providers/Models';

import { CustomerDetail } from './CustomerDetail';
import { OrderList } from '../Order/OrderList';
import { OrderDetail } from '../Order/OrderDetail';
import { OrderDetailModal } from '../Order/OrderDetailModal';
import { OrderModal } from '../Order/OrderModal';
import { ConfirmationModal, IConfirmationProps } from '../Common/ConfirmationModal';
import { ArticlePhotoModal, IArticlePhotoProps } from '../Common/ArticlePhotoModal';
import { CustomAlert, ICustomAlertProps } from '../Common/CustomAlert';

import * as Constants from '../Constants';

interface Props {
}

interface State {
  username: string,
  sessionKey: string,
  isSessionValid: boolean,
  customer: CustomerItem,
  articles: ArticleItem[],
  showOrderModal: boolean,
  showOrderDetailModal: boolean,
  showUploadModal: boolean,
  confirmationProps: IConfirmationProps | null | undefined,
  articlePhotoProps: IArticlePhotoProps | null | undefined,
  alertProps: ICustomAlertProps | null | undefined,
}

export class CustomerOrders extends React.Component<Props, State> {

  sessionProvider: SessionProvider;
  customerProvider: CustomerProvider;
  orderProvider: OrderProvider;
  articleProvider: ArticleProvider;

  orderSubject = new Rx.BehaviorSubject<OrderItem>(new OrderItem());
  orderModalSubject = new Rx.BehaviorSubject<OrderItem>(new OrderItem());
  orderDetailModalSubject = new Rx.BehaviorSubject<OrderDetailItem>(new OrderDetailItem());
  orderListSubject = new Rx.BehaviorSubject<OrderItem[]>([]);
  selectionSubject = new Rx.BehaviorSubject<number>(0);

  switchToArticleList: boolean = false;

  constructor(props: Props) {
    super(props);
    this.sessionProvider = new SessionProvider();
    this.customerProvider = new CustomerProvider();
    this.orderProvider = new OrderProvider();
    this.articleProvider = new ArticleProvider();

    const customer: CustomerItem = JSON.parse(sessionStorage.getItem(Constants.Customer) || '{}');

    this.state = {
      username: sessionStorage.getItem(Constants.Username) || '',
      sessionKey: sessionStorage.getItem(Constants.SessionKey) || '',
      isSessionValid: Boolean(sessionStorage.getItem(Constants.IsSessionValid) || ''),
      customer: customer,
      articles: [],
      showOrderModal: false,
      showOrderDetailModal: false,
      showUploadModal: false,
      confirmationProps: null,
      articlePhotoProps: null,
      alertProps: null,
    };
  }

  componentDidMount() {
    if (!this.state.isSessionValid)
      return;

    this.loadData();

    const orderId = Number(sessionStorage.getItem(Constants.SelectedOrderId) || '0');
    if (orderId > 0) {
      this.handleSelectOrder(orderId);
    }

    this.switchToArticleList = Boolean(sessionStorage.getItem(Constants.SwitchToArticleList) || '');
    sessionStorage.removeItem(Constants.SwitchToArticleList);
  }

  handleError = async (title: string, error: any): Promise<void> => {
    if (error.response !== null && error.response !== undefined && error.response.status === 401) {
      sessionStorage.removeItem(Constants.IsSessionValid);
      this.setState({ isSessionValid: false });
    }
    else {
      this.setState({
        alertProps: {
          title: title,
          error: error,
          closeHandler: async () => { this.setState({ alertProps: null }); },
          show: true,
        }
      });
    }
  }

  loadData = async (): Promise<void> => {
    try {
      const orders = await this.orderProvider.getOrders(this.state.username, this.state.sessionKey);
      const articles = await this.articleProvider.getArticles(this.state.username, this.state.sessionKey);

      orders.sort((a, b) => (a.orderDate === b.orderDate) ? ((a.id > b.id) ? -1 : 1) : (a.orderDate > b.orderDate) ? -1 : 1);

      if (this.state.customer.topArticlesOrders > 0) {
        articles.sort((a, b) => (a.orderCount === b.orderCount) ? ((a.name < b.name) ? -1 : 1) : (a.orderCount > b.orderCount) ? -1 : 1);
      }
      else if (this.state.customer.topArticlesAmount > 0) {
        articles.sort((a, b) => (a.orderAmount === b.orderAmount) ? ((a.name < b.name) ? -1 : 1) : (a.orderAmount > b.orderAmount) ? -1 : 1);
      }
      else {
        articles.sort((a, b) => (a.name < b.name) ? -1 : 1);
      }

      this.orderListSubject.next(orders);
      this.setState({ articles: articles });
    }
    catch (error) {
      this.handleError("Fehler beim Laden der Daten", error);
    }
  }

  handleSelectOrder = async (orderId: number): Promise<void> => {
    try {
      const order = await this.orderProvider.getOrderById(this.state.username, this.state.sessionKey, orderId);
      this.orderSubject.next(order);
      this.selectionSubject.next(orderId);
      sessionStorage.setItem(Constants.SelectedOrderId, String(orderId));
    }
    catch (error) {
      this.handleError("Fehler beim Auswählen der Bestellung", error);
    }
  }

  handleAddOrderClick = async (): Promise<void> => {
    try {
      const newOrder = await this.orderProvider.addOrder(this.state.username, this.state.sessionKey, new OrderItem());
      const orders = await this.orderProvider.getOrders(this.state.username, this.state.sessionKey);
      orders.sort((a, b) => (a.orderDate < b.orderDate) ? 1 : -1)
      this.orderSubject.next(newOrder);
      this.selectionSubject.next(newOrder.id);
      sessionStorage.setItem(Constants.SelectedOrderId, String(newOrder.id));
      this.orderListSubject.next(orders);
    }
    catch (error) {
      this.handleError("Fehler beim Hinzufügen der Bestellung", error);
    }
  }

  handleModifyOrderClick = async (orderId: number): Promise<void> => {
    try {
      const order = await this.orderProvider.getOrderById(this.state.username, this.state.sessionKey, orderId);
      this.orderSubject.next(order);
      this.orderModalSubject.next(order);
      this.selectionSubject.next(order.id);
      sessionStorage.setItem(Constants.SelectedOrderId, String(order.id));
      this.setState({ showOrderModal: true });
    }
    catch (error) {
      this.handleError("Fehler beim Ändern der Bestellung", error);
    }
  }

  handleModifyOrderOk = async (): Promise<void> => {
    try {
      const order = this.orderModalSubject.value;
      const modifiedOrder = await this.orderProvider.modifyOrder(this.state.username, this.state.sessionKey, order);
      const orders = await this.orderProvider.getOrders(this.state.username, this.state.sessionKey);
      orders.sort((a, b) => (a.orderDate < b.orderDate) ? 1 : -1)

      this.orderSubject.next(modifiedOrder);
      this.selectionSubject.next(modifiedOrder.id);
      sessionStorage.setItem(Constants.SelectedOrderId, String(modifiedOrder.id));
      this.orderListSubject.next(orders);
      this.setState({ showOrderModal: false });
    }
    catch (error) {
      this.setState({ showOrderModal: false });
      this.handleError("Fehler beim Ändern der Bestellung", error);
    }
  }

  handleSaveOrderClick = async (orderId: number): Promise<void> => {
    try {
      const order = await this.orderProvider.getOrderById(this.state.username, this.state.sessionKey, orderId);
      this.orderSubject.next(order);
      this.selectionSubject.next(orderId);
      sessionStorage.setItem(Constants.SelectedOrderId, String(orderId));
      this.setState({
        confirmationProps: {
          title: "Bestellung abschliessen",
          message: "Möchten Sie die Bestellung wirklich abschliessen?\nDie Bestellung kann danach nicht mehr verändert werden.",
          yesHandler: async () => { this.handleSaveOrderYes(orderId); },
          noHandler: async () => { this.setState({ confirmationProps: null }); },
          show: true
        }
      });
    }
    catch (error) {
      this.handleError("Fehler beim Abschliessen der Bestellung", error);
    }
  }

  handleSaveOrderYes = async (orderId: number): Promise<void> => {
    try {
      const order = await this.orderProvider.getOrderById(this.state.username, this.state.sessionKey, orderId);
      order.state = 1;
      const modifiedOrder = await this.orderProvider.modifyOrder(this.state.username, this.state.sessionKey, order);
      const orders = await this.orderProvider.getOrders(this.state.username, this.state.sessionKey);
      orders.sort((a, b) => (a.orderDate < b.orderDate) ? 1 : -1)
      this.orderSubject.next(modifiedOrder);
      this.selectionSubject.next(modifiedOrder.id);
      sessionStorage.setItem(Constants.SelectedOrderId, String(modifiedOrder.id));
      this.orderListSubject.next(orders);
      this.setState({ confirmationProps: null });
    }
    catch (error) {
      this.setState({ confirmationProps: null });
      this.handleError("Fehler beim Abschliessen der Bestellung", error);
    }
  }

  handleRemoveOrderClick = async (orderId: number): Promise<void> => {
    try {
      const order = await this.orderProvider.getOrderById(this.state.username, this.state.sessionKey, orderId);
      this.orderSubject.next(order);
      this.selectionSubject.next(orderId);
      sessionStorage.setItem(Constants.SelectedOrderId, String(orderId));
      this.setState({
        confirmationProps: {
          title: "Bestellung löschen",
          message: "Möchten Sie die Bestellung wirklich löschen?",
          yesHandler: async () => { this.handleRemoveOrderYes(orderId); },
          noHandler: async () => { this.setState({ confirmationProps: null }); },
          show: true
        }
      });
    }
    catch (error) {
      this.handleError("Fehler beim Löschen der Bestellung", error);
    }
  }

  handleRemoveOrderYes = async (orderId: number): Promise<void> => {
    try {
      await this.orderProvider.removeOrder(this.state.username, this.state.sessionKey, orderId);
      const orders = await this.orderProvider.getOrders(this.state.username, this.state.sessionKey);
      orders.sort((a, b) => (a.orderDate < b.orderDate) ? 1 : -1)
      const emptyOrder = new OrderItem();
      this.orderSubject.next(emptyOrder);
      this.selectionSubject.next(emptyOrder.id);
      sessionStorage.setItem(Constants.SelectedOrderId, String(emptyOrder.id));
      this.orderListSubject.next(orders);
      this.setState({ confirmationProps: null });
    }
    catch (error) {
      this.setState({ confirmationProps: null });
      this.handleError("Fehler beim Löschen der Bestellung", error);
    }
  }

  handleCopyOrderClick = async (orderId: number): Promise<void> => {
    try {
      const order = await this.orderProvider.getOrderById(this.state.username, this.state.sessionKey, orderId);
      this.orderSubject.next(order);
      this.selectionSubject.next(orderId);
      sessionStorage.setItem(Constants.SelectedOrderId, String(orderId));
      this.setState({
        confirmationProps: {
          title: "Bestellung kopieren",
          message: "Möchten Sie die Bestellung kopieren?",
          yesHandler: async () => { this.handleCopyOrderYes(orderId); },
          noHandler: async () => { this.setState({ confirmationProps: null }); },
          show: true
        }
      });
    }
    catch (error) {
      this.handleError("Fehler beim Kopieren der Bestellung", error);
    }
  }

  handleCopyOrderYes = async (orderId: number): Promise<void> => {
    try {
      const newOrder = await this.orderProvider.copyOrder(this.state.username, this.state.sessionKey, orderId);
      const orders = await this.orderProvider.getOrders(this.state.username, this.state.sessionKey);
      orders.sort((a, b) => (a.orderDate < b.orderDate) ? 1 : -1)

      this.orderSubject.next(newOrder);
      this.orderListSubject.next(orders);
      this.selectionSubject.next(newOrder.id);

      sessionStorage.setItem(Constants.SelectedOrderId, String(newOrder.id));
      this.setState({ confirmationProps: null });
    }
    catch (error) {
      this.setState({ confirmationProps: null });
      this.handleError("Fehler beim Kopieren der Bestellung", error);
    }
  }

  handleAddArticleClick = async (articleId: number): Promise<void> => {
    try {
      const orderId = this.orderSubject.value.id;
      const article = await this.articleProvider.getArticleById(this.state.username, this.state.sessionKey, articleId);
      const articleName = (this.state.customer.showArticleSecondLanguage && article.nameSecondLanguage) ? article.nameSecondLanguage : article.name;
      const detailItem = new OrderDetailItem({
        orderId: orderId, articleId: article.id, articleNo: article.no, articleName: articleName, webArticleInfo: article.webArticleInfo, unit: article.unit
      });
      const newOrderDetail = await this.orderProvider.addOrderArticle(this.state.username, this.state.sessionKey, detailItem);

      this.orderDetailModalSubject.next(newOrderDetail);
      this.setState({ showOrderDetailModal: true });
    }
    catch (error) {
      this.handleError("Fehler beim Hinzufügen der Bestellposition", error);
    }
  }

  handleShowArticlePhotoClick = async (articlePhotos: ArticlePhotoItem[]): Promise<void> => {
    try {
      this.setState({
        articlePhotoProps: {
          articlePhotos: articlePhotos,
          closeHandler: async () => { this.setState({ articlePhotoProps: null }); },
          show: true
        }
      });
    }
    catch (error) {
      this.handleError("Fehler beim Anzeigen der Artikelfotos", error);
    }
  }

  handleModifiyArticleClick = async (orderDetailId: number): Promise<void> => {
    try {
      const orderDetail = this.orderSubject.value.orderDetails.find(d => d.id === orderDetailId);
      if (!!!orderDetail) {
        return;
      }
      this.orderDetailModalSubject.next(orderDetail);
      this.setState({ showOrderDetailModal: true });
    }
    catch (error) {
      this.handleError("Fehler beim Ändern der Bestellposition", error);
    }
  }

  handleModifyArticleOk = async (): Promise<void> => {
    try {
      const orderId = this.orderSubject.value.id;
      const orderDetail = this.orderDetailModalSubject.value;
      await this.orderProvider.modifyOrderArticle(this.state.username, this.state.sessionKey, orderDetail);
      const order = await this.orderProvider.getOrderById(this.state.username, this.state.sessionKey, orderId);
      order.orderDetails.forEach((orderDetail: OrderDetailItem) => {
        orderDetail.timestamp = new Date();
      });

      this.orderSubject.next(order);
      this.selectionSubject.next(order.id);
      sessionStorage.setItem(Constants.SelectedOrderId, String(order.id));
      this.setState({ showOrderDetailModal: false });
    }
    catch (error) {
      this.setState({ showOrderDetailModal: false });
      this.handleError("Fehler beim Ändern der Bestellposition", error);
    }
  }

  handleRemoveArticleClick = async (orderDetailId: number): Promise<void> => {
    try {
      const orderId = this.orderSubject.value.id;
      await this.orderProvider.removeOrderArticle(this.state.username, this.state.sessionKey, orderDetailId);
      const order = await this.orderProvider.getOrderById(this.state.username, this.state.sessionKey, orderId);

      this.orderSubject.next(order);
      this.selectionSubject.next(order.id);
      sessionStorage.setItem(Constants.SelectedOrderId, String(order.id));
    }
    catch (error) {
      this.handleError("Fehler beim Löschen der Bestellposition", error);
    }
  }

  handleDropFile = async (files: any): Promise<void> => {
    try {
      this.setState({ showUploadModal: true, alertProps: null });

      const numberOfFiles = Number(files.length);
      if (numberOfFiles !== 1) {
        this.setState({ showUploadModal: false });
        this.handleError("Fehler beim Erstellen der Bestellung", "Es können nur einzelne Dateien hochgeladen werden.");
        return;
      }

      const fileSize = Number(files[0].size);
      if (fileSize > Constants.MaxFileDropSizeInKb * 1024) {
        this.setState({ showUploadModal: false });
        this.handleError("Fehler beim Erstellen der Bestellung", `Es können keine Dateien grösser als ${Constants.MaxFileDropSizeInKb} KB hochgeladen werden.`);
        return;
      }

      if (this.state.customer.barcodePluLength === 0) {
        this.setState({ showUploadModal: false });
        this.handleError("Fehler beim Erstellen der Bestellung", `Barcode lesen in der Länge von 0 nicht möglich.`);
        return;
      }

      const newOrder = await this.orderProvider.uploadBarcodeFile(this.state.username, this.state.sessionKey, files[0]);
      const orders = await this.orderProvider.getOrders(this.state.username, this.state.sessionKey);
      orders.sort((a, b) => (a.orderDate < b.orderDate) ? 1 : -1)

      this.orderSubject.next(newOrder);
      this.orderListSubject.next(orders);
      this.selectionSubject.next(newOrder.id);

      sessionStorage.setItem(Constants.SelectedOrderId, String(newOrder.id));
      this.setState({ showUploadModal: false });
    }
    catch (error) {
      this.setState({ showUploadModal: false });
      this.handleError("Fehler beim Erstellen der Bestellung", error);
    }
  }

  render() {
    if (this.state.username === "" || this.state.sessionKey === "") {
      return (<Redirect to="/" />);
    }

    if (!this.state.isSessionValid) {
      return (
        <CustomAlert
          title={Constants.SessionExpiredTitle}
          error={Constants.SessionExpiredMessage}
          closeHandler={async () => {
            sessionStorage.removeItem(Constants.Username);
            sessionStorage.removeItem(Constants.SessionKey);
            this.setState({
              username: "",
              sessionKey: "",
            });
          }}
          show={true}
        />
      )
    }

    if (this.state.customer.isWebAdmin) {
      return (
        <CustomAlert
          title={Constants.AdminPageNotAvailableTitle}
          error={Constants.AdminPageNotAvailableMessage}
          show={true}
        />
      )
    }

    if (this.switchToArticleList) {
      return <Redirect to={"/orderlist"} />
    }

    return (
      <div>
        <Container className="p-0">
          <CustomAlert
            {...this.state.alertProps}
          />
          <Row>
            <Col>
              <CustomerDetail customer={this.state.customer} />
            </Col>
          </Row>
          <Row>
            <Col>
              <OrderList
                selectOrderHandler={this.handleSelectOrder}
                addOrderHandler={this.handleAddOrderClick}
                modifyOrderHandler={this.handleModifyOrderClick}
                saveOrderHandler={this.handleSaveOrderClick}
                removeOrderHandler={this.handleRemoveOrderClick}
                copyOrderHandler={this.handleCopyOrderClick}
                dropFileHandler={this.handleDropFile}
                orderListSubject={this.orderListSubject}
                selectionSubject={this.selectionSubject}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <OrderDetail
                articles={this.state.articles}
                addArticleHandler={this.handleAddArticleClick}
                showArticlePhotoHandler={this.handleShowArticlePhotoClick}
                modifyArticleHandler={this.handleModifiyArticleClick}
                removeArticleHandler={this.handleRemoveArticleClick}
                subject={this.orderSubject}
              />
            </Col>
          </Row>
        </Container>
        <OrderModal
          okHandler={() => { this.handleModifyOrderOk() }}
          cancelHandler={() => { this.setState({ showOrderModal: false }) }}
          show={this.state.showOrderModal}
          subject={this.orderModalSubject}
        />
        <OrderDetailModal
          okHandler={() => { this.handleModifyArticleOk() }}
          cancelHandler={() => { this.setState({ showOrderDetailModal: false }) }}
          show={this.state.showOrderDetailModal}
          subject={this.orderDetailModalSubject}
        />
        <ConfirmationModal
          {...this.state.confirmationProps}
        />
        <ArticlePhotoModal
          {...this.state.articlePhotoProps}
        />
        {
          this.state.showUploadModal && <Modal show={true} animation={false} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Datei hochladen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center">
                <Spinner as="span" animation="border" variant="success" role="status" />
                <span className="ml-4" style={{ fontSize: "large" }}>Bestellungen werden erstellt...</span>
              </div>
            </Modal.Body>
          </Modal>
        }
      </div >
    );
  }
}
