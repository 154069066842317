import React from 'react';

interface Props {
  dropHandler: (files: any) => void,
}

interface State {
  dragging: boolean,
}

export class DragAndDrop extends React.Component<Props, State> {
  dropRef: React.RefObject<HTMLInputElement>;
  dragCounter: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = { dragging: false };
    this.dropRef = React.createRef();
  }

  componentDidMount() {
    this.dragCounter = 0;
    let div = this.dropRef.current;
    if (div !== null) {
      div.addEventListener('dragenter', this.handleDragEnter);
      div.addEventListener('dragleave', this.handleDragLeave);
      div.addEventListener('dragover', this.handleDragOver);
      div.addEventListener('drop', this.handleDrop);
    }
  }

  componentWillUnmount() {
    let div = this.dropRef.current;
    if (div !== null) {
      div.removeEventListener('dragenter', this.handleDragEnter);
      div.removeEventListener('dragleave', this.handleDragLeave);
      div.removeEventListener('dragover', this.handleDragOver);
      div.removeEventListener('drop', this.handleDrop);
    }
  }

  handleDragEnter = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    this.dragCounter++;
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      this.setState({ dragging: true })
    }
  }

  handleDragLeave = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ dragging: false });
    }
  }

  handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  }

  handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ dragging: false });
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      this.props.dropHandler(event.dataTransfer.files);
      event.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  }

  render() {
    return (
      <div ref={this.dropRef}>
        {this.state.dragging &&
          <div style={{ border: 'dashed grey 2px', backgroundColor: 'rgba(255,255,255,.8)', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 9999 }}>
            <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
              <h2>Neue Bestellung anlegen</h2>
            </div>
          </div>
        }
        {this.props.children}
      </div>
    )
  }
}
