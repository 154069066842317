import * as Axios from 'axios';

import { PromotionItem } from '../providers/Models';

export class PromotionProvider {

  async getPromotions(username: string, sessionKey: string, validOn: Date): Promise<PromotionItem[]> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/promotion?username=${username || ''}&sessionkey=${sessionKey || ''}&validOn=${validOn.toISOString()}`,
      method: 'GET',
      headers: { Accept: 'application/json; odata=verbose' }
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }

  async getPromotionById(username: string, sessionKey: string, promotionId: number): Promise<PromotionItem> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/promotion/${promotionId}?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'GET',
      headers: { Accept: 'application/json; odata=verbose' }
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }
}
