import React from 'react';
import { Redirect } from 'react-router';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import * as Rx from "rxjs";

import { SessionProvider } from '../../providers/SessionProvider';
import { CustomerProvider } from '../../providers/CustomerProvider';
import { CustomAlert, ICustomAlertProps } from './CustomAlert';

import * as Constants from '../Constants';

interface Props {
  eventSubject: Rx.BehaviorSubject<Date>,
}

interface State {
  username: string;
  password: string;
  isError: boolean;
  redirectUrl: any;
  alertProps: ICustomAlertProps | null | undefined;
}

export class Login extends React.Component<Props, State> {

  sessionProvider: SessionProvider;
  customerProvider: CustomerProvider;

  constructor(props: Props) {
    super(props);
    sessionStorage.removeItem(Constants.Username);
    sessionStorage.removeItem(Constants.SessionKey);
    sessionStorage.removeItem(Constants.IsSessionValid);
    sessionStorage.removeItem(Constants.SelectedOrderId);
    sessionStorage.removeItem(Constants.Customer);

    this.sessionProvider = new SessionProvider();
    this.customerProvider = new CustomerProvider();

    this.state = {
      username: '',
      password: '',
      isError: false,
      redirectUrl: '',
      alertProps: null,
    };
  }

  handleError = async (title: string, error: any): Promise<void> => {
    this.setState({
      alertProps: {
        title: title,
        error: error,
        closeHandler: async () => { this.setState({ alertProps: null }); },
        show: true,
      }
    });
  }

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();

      const sessionKey = await this.sessionProvider.getSessionKey(this.state.username, this.state.password);
      sessionStorage.setItem(Constants.Username, this.state.username);
      sessionStorage.setItem(Constants.SessionKey, sessionKey);
      sessionStorage.setItem(Constants.IsSessionValid, String(true));

      const customer = await this.customerProvider.getCurrent(this.state.username, sessionKey);
      sessionStorage.setItem(Constants.Customer, JSON.stringify(customer));

      if (customer.switchToArticleList) {
        sessionStorage.setItem(Constants.SwitchToArticleList, String(true));  
      }
      else {
        sessionStorage.removeItem(Constants.SwitchToArticleList);
      }

      this.props.eventSubject.next(new Date());

      if (customer.isWebAdmin) {
        this.setState({ redirectUrl: "/customeradminview" });
      }
      else {
        this.setState({ redirectUrl: "/customerorders" });
      }
    }
    catch (error) {
      this.handleError("Anmeldung nicht erfolgreich", error);
    }
  }

  render() {
    if (this.state.redirectUrl) {
      return <Redirect to={this.state.redirectUrl} />
    }
    return (
      <React.Fragment>
        <CustomAlert
          {...this.state.alertProps}
        />
        <Form onSubmit={this.handleSubmit}>
          <h1>Anmelden</h1>
          <Form.Group>
            <Form.Control placeholder="Benutzername" onChange={event => this.setState({ username: event.currentTarget.value })} />
          </Form.Group>
          <Form.Group>
            <Form.Control type="password" placeholder="Kennwort" onChange={event => this.setState({ password: event.currentTarget.value })} />
          </Form.Group>
          <Button variant="primary" type="submit">Anmelden</Button>
        </Form>
      </React.Fragment>
    );
  }
}
