import React from 'react';
import BootstrapTable, { SelectRowProps } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import * as Rx from "rxjs";
import moment from 'moment';

import { OrderItem, PromotionItem } from '../../providers/Models';

interface Props {
  promotions: PromotionItem[],
  selectPromotionHandler: (promotionId: number) => void,
  subject: Rx.BehaviorSubject<number>
}

interface State {
  selected: number[]
}

export class PromotionList extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { selected: [] };
  }

  componentDidMount() {
    this.props.subject.subscribe((data) => {
      this.setState({ selected: [data] });
    });
  }

  componentWillUnmount() {
    this.props.subject.unsubscribe();
  }

  dateFormatter = (cell: any, _row: any) => {
    return (
      <React.Fragment>
        { !!cell ? moment(cell).format('DD.MM.YYYY') : ""}
      </React.Fragment>
    );
  }

  columnEvents = {
    onClick: (_e: any, _column: any, _columnIndex: any, row: OrderItem, _rowIndex: any) => {
      this.props.selectPromotionHandler(row.id);
    }
  }

  columns = [
    { dataField: 'title', text: 'Aktion', events: this.columnEvents },
    { dataField: 'validFrom', text: 'Gültig von', formatter: this.dateFormatter, events: this.columnEvents },
    { dataField: 'validUntil', text: 'Gültig bis', formatter: this.dateFormatter, events: this.columnEvents },
  ];

  render() {

    const selectRow: SelectRowProps<any> = {
      mode: 'radio',
      hideSelectColumn: true,
      clickToSelect: false,
      selected: this.state.selected,
      style: { background: 'lightgray' }
    };

    return (
      <BootstrapTable
        hover
        classes="table-sm w-auto"
        keyField='id'
        data={this.props.promotions}
        columns={this.columns}
        selectRow={selectRow}
        pagination={paginationFactory({ sizePerPage: 5, hidePageListOnlyOnePage: true, hideSizePerPage: true })} />
    );
  }
}
