import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export interface IConfirmationProps {
  title?: string | null | undefined,
  message?: string | null | undefined,
  yesHandler?: () => Promise<void> | null | undefined,
  noHandler?: () => Promise<void> | null | undefined,
  show?: boolean | null | undefined,
}

interface State {
}

export class ConfirmationModal extends React.Component<IConfirmationProps, State> {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => { if (this.props.noHandler) this.props.noHandler() }}
        animation={false}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.message?.split('\n').map((str, index) => <p key={index} className="m-0">{str}</p>)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => { if (this.props.yesHandler) this.props.yesHandler() }}>Ja</Button>
          <Button variant="secondary" onClick={() => { if (this.props.noHandler) this.props.noHandler() }}>Nein</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
