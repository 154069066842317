import React from 'react';
import { Pencil, Trash } from 'react-bootstrap-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import * as Rx from "rxjs";

import { OrderItem, OrderDetailItem } from '../../providers/Models';
import ReactTooltip from 'react-tooltip';

interface Props {
  modifyArticleHandler: (orderDetailId: number) => void,
  removeArticleHandler: (orderDetailId: number) => void,
  subject: Rx.BehaviorSubject<OrderItem>
}

interface State {
  order: OrderItem,
  columns: any[]
}

export class OrderArticleList extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { order: new OrderItem(), columns: [{ dataField: "", text: "" }] };
  }

  componentDidMount() {
    this.props.subject.subscribe((data) => {
      this.setState({
        order: data,
        columns: [
          { dataField: 'articleNo', text: 'Nr' },
          { dataField: 'articleName', text: 'Name', formatter: this.nameFormatter },
          { dataField: 'amount', text: 'Menge', align: 'right', formatter: this.amountFormatter },
          { dataField: 'unit', text: 'Einheit', formatter: this.unitInfoFormatter },
          { dataField: '', text: '', isDummyField: true, headerStyle: () => { return { width: "65px" }; }, formatExtraData: data.state, formatter: this.actionFormatter }
        ]
      });
    });
  }

  componentWillUnmount() {
    this.props.subject.unsubscribe();
  }

  actionFormatter = (_cell: any, row: OrderDetailItem, _rowIndex: any, extra: any) => {
    if (extra === 0) {
      return (
        <div className="d-flex justify-content-center">
          <div data-tip data-for="modifyArticleTip" style={{ cursor: "pointer" }}><Pencil onClick={() => this.props.modifyArticleHandler(row.id)} /></div>
          <div data-tip data-for="removeArticleTip" className="ml-2" style={{ cursor: "pointer" }}><Trash onClick={() => this.props.removeArticleHandler(row.id)} /></div>
          <ReactTooltip id="modifyArticleTip" place="top" effect="solid">Artikel bearbeiten</ReactTooltip>
          <ReactTooltip id="removeArticleTip" place="top" effect="solid">Artikel löschen</ReactTooltip>
        </div>
      );
    }
    else {
      return (
        <div />
      );
    }
  };

  nameFormatter = (_cell: any, row: OrderDetailItem, _rowIndex: any, _extra: any) => {
    return (
      <React.Fragment>{row.articleName}{row.comment?.length > 0 ? " *" : ""}</React.Fragment>
    );
  }

  amountFormatter = (cell: any, _row: OrderDetailItem, _rowIndex: any, _extra: any) => {
    return (
      <React.Fragment>
        { isNaN(cell) ? "" : cell.toFixed(3)}
      </React.Fragment>
    );
  }

  unitInfoFormatter = (_cell: any, row: OrderDetailItem, _rowIndex: any, _extra: any) => {
    return (
      <React.Fragment>{row.isOrderlist && row.webArticleInfo ? row.webArticleInfo : row.unit}</React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ height: "2.8em" }}>
          <h3>Artikel auf Bestellung</h3>
        </div>
        <BootstrapTable
          hover
          classes="table-sm w-auto"
          keyField='id'
          data={this.state.order.orderDetails}
          columns={this.state.columns}
          pagination={paginationFactory({ sizePerPage: 5, hidePageListOnlyOnePage: true, hideSizePerPage: true })}
        />
      </React.Fragment>
    );
  }
}
