import * as Axios from 'axios';

import { ArticleItem } from '../providers/Models';

export class ArticleProvider {

  async getArticles(username: string, sessionKey: string): Promise<ArticleItem[]> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/article?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'GET',
      headers: { Accept: 'application/json; odata=verbose' }
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }

  async getArticleById(username: string, sessionKey: string, articleId: number): Promise<ArticleItem> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/article/${articleId}?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'GET',
      headers: { Accept: 'application/json; odata=verbose' }
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }
}
