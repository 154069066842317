import React from 'react';
import { Redirect } from 'react-router';
import { Col, Container, Row } from 'react-bootstrap';

import * as Rx from "rxjs";

import { CustomerProvider } from '../../providers/CustomerProvider';
import { OrderProvider } from '../../providers/OrderProvider';
import { CustomerItem, OrderItem } from '../../providers/Models';
import { CustomAlert, ICustomAlertProps } from '../Common/CustomAlert';
import { CustomerDetail } from '../Customer/CustomerDetail';

import * as Constants from '../Constants';
import { AdminOrderList } from '../Order/AdminOrderList';

interface Props { }

interface State {
  username: string,
  sessionKey: string,
  isSessionValid: boolean,
  customer: CustomerItem,
  alertProps: ICustomAlertProps | null | undefined,
}

export class CustomerAdminView extends React.Component<Props, State> {

  customerProvider: CustomerProvider;
  orderProvider: OrderProvider;

  orderListSubject = new Rx.BehaviorSubject<OrderItem[]>([]);

  constructor(props: Props) {
    super(props);
    this.customerProvider = new CustomerProvider();
    this.orderProvider = new OrderProvider();

    const customer: CustomerItem = JSON.parse(sessionStorage.getItem(Constants.Customer) || '{}');

    this.state = {
      username: sessionStorage.getItem(Constants.Username) || '',
      sessionKey: sessionStorage.getItem(Constants.SessionKey) || '',
      isSessionValid: Boolean(sessionStorage.getItem(Constants.IsSessionValid) || ''),
      customer: customer,
      alertProps: null,
    };
  }

  componentDidMount() {
    if (!this.state.isSessionValid)
      return;

    this.loadData();
  }

  handleError = async (title: string, error: any): Promise<void> => {
    if (error.response !== null && error.response !== undefined && error.response.status === 401) {
      sessionStorage.removeItem(Constants.IsSessionValid);
      this.setState({ isSessionValid: false });
    }
    else {
      this.setState({
        alertProps: {
          title: title,
          error: error,
          closeHandler: async () => { this.setState({ alertProps: null }); },
          show: true,
        }
      });
    }
  }

  loadData = async (): Promise<void> => {
    try {
      const orders = await this.orderProvider.getOrdersAdmin(this.state.username, this.state.sessionKey);
      orders.sort((a, b) => (a.orderDate > b.orderDate) ? -1 : 1)
      this.orderListSubject.next(orders);
    }
    catch (error) {
      this.handleError("Fehler beim Laden der Daten", error);
    }
  }

  render() {
    if (this.state.username === "" || this.state.sessionKey === "") {
      return (<Redirect to="/" />);
    }

    if (!this.state.isSessionValid) {
      return (
        <CustomAlert
          title={Constants.SessionExpiredTitle}
          error={Constants.SessionExpiredMessage}
          closeHandler={async () => {
            sessionStorage.removeItem(Constants.Username);
            sessionStorage.removeItem(Constants.SessionKey);
            this.setState({
              username: "",
              sessionKey: "",
            });
          }}
          show={true}
        />
      )
    }

    if (!this.state.customer.isWebAdmin) {
      return (
        <CustomAlert
          title={Constants.AdminPageNotAvailableTitle}
          error={Constants.AdminPageAvailableMessage}
          show={true}
        />
      )
    }

    return (
      <div>
        <Container className="p-0">
          <CustomAlert
            {...this.state.alertProps}
          />
          <Row>
            <Col>
              <CustomerDetail customer={this.state.customer} />
            </Col>
          </Row>
          <Row>
            <Col>
              <AdminOrderList
                orderListSubject={this.orderListSubject}
              />
            </Col>
          </Row>
        </Container>
      </div >
    );
  }
}
