import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import * as Rx from "rxjs";
import moment from 'moment';

import { OrderItem, OrderState } from '../../providers/Models';

interface Props {
  orderListSubject: Rx.BehaviorSubject<OrderItem[]>,
}

interface State {
  orders: OrderItem[]
}

export class AdminOrderList extends React.Component<Props, State> {

  node: any;

  constructor(props: Props) {
    super(props);
    this.state = { orders: [] };
  }

  componentDidMount() {
    this.props.orderListSubject.subscribe((data) => {
      this.setState({ orders: data });
    });
  }

  componentWillUnmount() {
    this.props.orderListSubject.unsubscribe();
  }

  customerFormatter = (cell: any, _row: any) => {
    return (
      <React.Fragment>
        {!!cell ? `${cell.company}` : "-"}
      </React.Fragment>
    );
  }

  dateFormatter = (cell: any, _row: any) => {
    return (
      <React.Fragment>
        {!!cell ? moment(cell).format('DD.MM.YYYY') : ""}
      </React.Fragment>
    );
  }

  stateFormatter = (cell: any, _row: any) => {
    return (
      <React.Fragment>
        {!!OrderState[cell] ? OrderState[cell] : "-"}
      </React.Fragment>
    );
  }

  columns = [
    { dataField: 'customer', text: 'Kunde', formatter: this.customerFormatter },
    { dataField: 'orderDate', text: 'Bestelldatum', formatter: this.dateFormatter },
    { dataField: 'deliveryDate', text: 'Lieferdatum', headerClasses: 'd-none d-md-table-cell', classes: 'd-none d-md-table-cell', formatter: this.dateFormatter },
    { dataField: 'comment', text: 'Bemerkung', headerClasses: 'd-none d-md-table-cell', classes: 'd-none d-md-table-cell' },
    { dataField: 'state', text: 'Status', formatter: this.stateFormatter },
  ];

  render() {
    return (
      <BootstrapTable
        hover
        classes="table-sm w-auto"
        keyField='id'
        data={this.state.orders}
        columns={this.columns}
        pagination={paginationFactory({ sizePerPage: 15, hidePageListOnlyOnePage: true, hideSizePerPage: true })}
        ref={(n) => (this.node = n)}
      />
    );
  }
}
