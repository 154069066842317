import React from 'react';
import { Carousel, Modal } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { ArticlePhotoItem } from '../../providers/Models';

import * as Constants from '../Constants';

export interface IArticlePhotoProps {
  articlePhotos?: ArticlePhotoItem[] | null | undefined,
  closeHandler?: () => Promise<void> | null | undefined,
  show?: boolean | null | undefined,
}

interface State {
  username: string,
  sessionKey: string,
}

export class ArticlePhotoModal extends React.Component<IArticlePhotoProps, State> {

  constructor(props: IArticlePhotoProps) {
    super(props);

    this.state = {
      username: sessionStorage.getItem(Constants.Username) || '',
      sessionKey: sessionStorage.getItem(Constants.SessionKey) || '',
    };
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => { if (this.props.closeHandler) this.props.closeHandler() }}
        animation={false}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Artikelfoto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel>
            {this.props.articlePhotos && this.props.articlePhotos.map((articlePhoto, index) => (
              <Carousel.Item key={index}>
                <Image className="mx-auto d-block" src={`${process.env.REACT_APP_API_URL}/articlephoto?username=${this.state.username}&sessionkey=${this.state.sessionKey}&id=${articlePhoto.id}`} fluid />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    );
  }
}
