import React from 'react';
import { Route } from 'react-router';

import * as Rx from "rxjs";

import { Layout } from './components/Layout';
import { Login } from './components/Common/Login';
import { Logout } from './components/Common/Logout';
import { CustomerOrders } from './components/Customer/CustomerOrders';
import { CustomerOrderList } from './components/Customer/CustomerOrderlist';
import { CustomerDocuments } from './components/Customer/CustomerDocuments';
import { CustomerPromotions } from './components/Customer/CustomerPromotions';
import { CustomerAdminView } from './components/Customer/CustomerAdminView';

import './custom.css'

export class App extends React.Component<{}, {}> {

  eventSubject = new Rx.BehaviorSubject<Date>(new Date());

  render() {
    return (
      <Layout eventSubject={this.eventSubject}>
        <Route exact path='/' component={() => <Login eventSubject={this.eventSubject} />} />
        <Route exact path="/customerorders" component={() => <CustomerOrders />} />
        <Route exact path="/customeradminview" component={() => <CustomerAdminView />} />
        <Route exact path="/orderlist" component={() => <CustomerOrderList />} />
        <Route exact path="/documents" component={() => <CustomerDocuments />} />
        <Route exact path="/promotions" component={() => <CustomerPromotions />} />
        <Route exact path='/logout' component={Logout} />
      </Layout>
    );
  }
}
