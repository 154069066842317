import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';

import * as Rx from "rxjs";

import { CustomerItem } from '../providers/Models';
import * as Constants from '../components/Constants';

import './NavMenu.css';

export class NavMenu extends React.Component<{ eventSubject: Rx.BehaviorSubject<Date> }, { collapsed: boolean, customer: CustomerItem }> {
  static displayName = NavMenu.name;

  constructor(props: { eventSubject: Rx.BehaviorSubject<Date> }) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = { collapsed: true, customer: new CustomerItem() };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  componentDidMount() {
    this.props.eventSubject.subscribe((data) => {
      const customer: CustomerItem = JSON.parse(sessionStorage.getItem(Constants.Customer) || '{}');
      this.setState({ customer: customer });
    });
  }

  componentWillUnmount() {
    this.props.eventSubject.unsubscribe();
  }

  render() {
    return (
      <header>
        <Navbar bg="light" expand="lg" className="border-bottom box-shadow mb-3">
          <Container>
            {(this.state.customer.id === undefined || (this.state.customer.id > 0 && !this.state.customer.isWebAdmin)) && <Navbar.Brand href="/customerorders">RickOfficeWeb</Navbar.Brand>}
            {this.state.customer.id > 0 && this.state.customer.isWebAdmin && <Navbar.Brand href="/customeradminview">RickOfficeWeb</Navbar.Brand>}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                {this.state.customer.id > 0 && !this.state.customer.isWebAdmin && this.state.customer.isOrderlist && <Nav.Link className="text-dark" href="/orderlist">Bestellliste</Nav.Link>}
                {this.state.customer.id > 0 && !this.state.customer.isWebAdmin && <Nav.Link className="text-dark" href="/documents">Dokumente</Nav.Link>}
                {this.state.customer.id > 0 && !this.state.customer.isWebAdmin && <Nav.Link className="text-dark" href="/promotions">Aktionen</Nav.Link>}
                <Nav.Link className="text-dark" href="/logout">Abmelden</Nav.Link>
                <a className="nav-link text-dark">{Constants.VersionInfo}</a>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
