import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { CustomerItem } from '../../providers/Models';

import * as Constants from '../Constants';

interface Props {
  customer: CustomerItem,
}

interface State {
  username: string,
  sessionKey: string,
}

export class CustomerDetail extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      username: sessionStorage.getItem(Constants.Username) || '',
      sessionKey: sessionStorage.getItem(Constants.SessionKey) || '',
    };
  }

  render() {

    return (
      <React.Fragment>
        <Row>
          <Col className="col-8 col-md-5 order-md-1 mx-auto">
            {!!this.props.customer.client.logoname &&
              <Image className="mb-4 float-md-right" src={`${process.env.REACT_APP_API_URL}/logo?username=${this.state.username}&sessionkey=${this.state.sessionKey}`} fluid />}
          </Col>
          <Col className="col-12 col-md-7 order-md-0">
            <h2>Kunde</h2>
            <p>Mandant: {this.props.customer.client.name}</p>
            <p><span>Kunde: </span>
              {this.props.customer.company && <span>{this.props.customer.company} </span>}
              {this.props.customer.firstName && <span>{this.props.customer.firstName} </span>}
              {this.props.customer.lastName && <span>{this.props.customer.lastName}</span>}
            </p>
          </Col>
        </Row>
        {!!this.props.customer.client.messageToAllClients && this.props.customer.client.messageToAllClients !== "" &&
          <Row>
            <Col>
              <Alert variant="info">
                {this.props.customer.client.messageToAllClients}
              </Alert>
            </Col>
          </Row>}
          {!!this.props.customer.message && this.props.customer.message !== "" &&
          <Row>
            <Col>
              <Alert variant="info">
                {this.props.customer.message}
              </Alert>
            </Col>
          </Row>}
      </React.Fragment>
    );
  }
}
