import * as Axios from 'axios';

export class SessionProvider {

  async getSessionKey(username: string, password: string): Promise<string> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/session/GetSessionKey`,
      method: 'POST',
      headers: { Accept: 'application/json; odata=verbose' },
      data: { username: username || '', password: password || '' }
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }

  async validateSessionKey(username: string, sessionKey: string): Promise<boolean> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/session/ValidateSessionKey?username=${username}&sessionkey=${sessionKey}`,
      method: 'GET',
      headers: {
        Accept: 'application/json; odata=verbose',
      }
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }
}
