import React from 'react';
import { Redirect } from 'react-router';
import { Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { CustomerProvider } from '../../providers/CustomerProvider';
import { OrderProvider } from '../../providers/OrderProvider';
import { OrderlistProvider } from '../../providers/OrderlistProvider';

import { CustomerItem, OrderItem, ArticlelistDetailItem, OrderDetailItem } from '../../providers/Models';

import { CustomerDetail } from './CustomerDetail';
import { OrderDetailHeader } from '../Order/OrderDetailHeader';
import { OrderlistDetailModal } from '../Customer/OrderlistDetailModal';
import { CustomAlert, ICustomAlertProps } from '../Common/CustomAlert';
import { ConfirmationModal, IConfirmationProps } from '../Common/ConfirmationModal';

import * as Constants from '../Constants';

const { SearchBar } = Search;

interface Props {
}

interface State {
  username: string,
  sessionKey: string,
  isSessionValid: boolean,
  customer: CustomerItem,
  order: OrderItem,
  articles: ArticlelistDetailItem[],
  orderlistDetail: ArticlelistDetailItem,
  showMengeModal: boolean,
  showSaveModal: boolean,
  confirmationProps: IConfirmationProps | null | undefined,
  alertProps: ICustomAlertProps | null | undefined,
}

interface ColumnMatchProps {
  searchText: string;
  value: any;
  column: {
    dataField: string;
    text: string;
  };
  row: ArticlelistDetailItem;
}

function onColumnMatch({ searchText, value, column, row }: ColumnMatchProps): boolean {
  const searchValue = (searchText ?? "").toLocaleLowerCase();

  if (column.dataField === 'article.name') {
    const nameValue = (row.article?.name ?? "").toLocaleLowerCase();
    const nameSecondLanguageValue = (row.article?.nameSecondLanguage ?? "").toLocaleLowerCase();
    return nameValue.includes(searchValue) || nameSecondLanguageValue.includes(searchValue);
  }

  const stringValue = (value?.toString() ?? "").toLocaleLowerCase();
  return stringValue.includes(searchValue);
}

export class CustomerOrderList extends React.Component<Props, State> {

  customerProvider: CustomerProvider;
  orderProvider: OrderProvider;
  orderlistProvider: OrderlistProvider;

  constructor(props: Props) {
    super(props);
    this.customerProvider = new CustomerProvider();
    this.orderProvider = new OrderProvider();
    this.orderlistProvider = new OrderlistProvider();

    const customer: CustomerItem = JSON.parse(sessionStorage.getItem(Constants.Customer) || '{}');

    this.state = {
      username: sessionStorage.getItem(Constants.Username) || '',
      sessionKey: sessionStorage.getItem(Constants.SessionKey) || '',
      isSessionValid: Boolean(sessionStorage.getItem(Constants.IsSessionValid) || ''),
      customer: customer,
      order: new OrderItem(),
      articles: [],
      orderlistDetail: new ArticlelistDetailItem(),
      showMengeModal: false,
      showSaveModal: false,
      confirmationProps: null,
      alertProps: null,
    };
  }

  componentDidMount() {
    if (!this.state.isSessionValid)
      return;

    this.loadData();
  }

  handleError = async (title: string, error: any): Promise<void> => {
    if (error.response !== null && error.response !== undefined && error.response.status === 401) {
      sessionStorage.removeItem(Constants.IsSessionValid);
      this.setState({ isSessionValid: false });
    }
    else {
      this.setState({
        alertProps: {
          title: title,
          error: error,
          closeHandler: async () => { this.setState({ alertProps: null }); },
          show: true,
        }
      });
    }
  }

  loadData = async (): Promise<void> => {
    try {
      const articles = await this.orderlistProvider.getArticlelistDetails(this.state.username, this.state.sessionKey);

      var order = new OrderItem();
      const orderId = Number(sessionStorage.getItem(Constants.SelectedOrderId) || '0');
      if (orderId > 0) {
        order = await this.orderProvider.getOrderById(this.state.username, this.state.sessionKey, orderId);
        order.orderDetails.forEach((orderDetail: OrderDetailItem) => {
          const orderlistDetail = articles.find(d => d.article?.id === orderDetail.articleId);
          if (!!orderlistDetail) {
            if (orderDetail.article.articlePortioning === true) {
              var copiedOrderlistDetail = new ArticlelistDetailItem();
              copiedOrderlistDetail.amount = orderDetail.amount;
              copiedOrderlistDetail.portioningAmount = orderDetail.portioningAmount;
              copiedOrderlistDetail.comment = orderDetail.comment;
              copiedOrderlistDetail.sortOrder = orderlistDetail.sortOrder;
              copiedOrderlistDetail.article = orderlistDetail.article;
              copiedOrderlistDetail.orderDetailItemId = orderDetail.id;
              articles.push(copiedOrderlistDetail);
            }
            else {
              orderlistDetail.amount = orderDetail.amount;
              orderlistDetail.portioningAmount = orderDetail.portioningAmount;
              orderlistDetail.comment = orderDetail.comment;
              orderlistDetail.orderDetailItemId = orderDetail.id;
            }
          }
        });
      }

      articles.forEach((article: ArticlelistDetailItem) => {
        article.compoundKey = `${article.id};${article.orderDetailItemId}`;
      });

      articles.sort((a, b) => {
        var detailIdA = (a.orderDetailItemId || 0) === 0 ? Number.MAX_SAFE_INTEGER : a.orderDetailItemId;
        var detailIdB = (b.orderDetailItemId || 0) === 0 ? Number.MAX_SAFE_INTEGER : b.orderDetailItemId;
        if (a.sortOrder === b.sortOrder) {
          return detailIdA < detailIdB ? -1 : 1;
        }
        return a.sortOrder < b.sortOrder ? -1 : 1;
      });

      this.setState({ order: order, articles: articles });
    }
    catch (error) {
      this.handleError("Fehler beim Laden der Daten", error);
    }
  }

  saveOrderDetail = async (orderlistDetail: ArticlelistDetailItem): Promise<void> => {
    try {
      if (this.state.order.id === 0 || isNaN(orderlistDetail.amount) || orderlistDetail.article == null) {
        return;
      }

      var orderDetail = this.state.order.orderDetails.find(d => d.id === orderlistDetail.orderDetailItemId);
      const articleName = (this.state.customer.showArticleSecondLanguage && orderlistDetail.article.nameSecondLanguage) ? orderlistDetail.article.nameSecondLanguage : orderlistDetail.article.name;

      if (!!!orderDetail) {
        const detailItem = new OrderDetailItem({
          orderId: this.state.order.id,
          articleId: orderlistDetail.article.id,
          articleNo: orderlistDetail.article.no,
          articleName: articleName,
          webArticleInfo: orderlistDetail.article.webArticleInfo,
          amount: orderlistDetail.amount,
          portioningAmount: orderlistDetail.portioningAmount,
          comment: orderlistDetail.comment,
          unit: orderlistDetail.article.unit,
          isOrderlist: true
        });
        await this.orderProvider.addOrderArticle(this.state.username, this.state.sessionKey, detailItem);
      }
      else {
        if (orderlistDetail.amount > 0) {
          orderDetail.amount = orderlistDetail.amount;
          orderDetail.portioningAmount = orderlistDetail.portioningAmount;
          orderDetail.comment = orderlistDetail.comment;
          orderDetail.isOrderlist = true;
          await this.orderProvider.modifyOrderArticle(this.state.username, this.state.sessionKey, orderDetail);
        }
        else {
          await this.orderProvider.removeOrderArticle(this.state.username, this.state.sessionKey, orderDetail.id);
        }
      }

      const orderId = Number(sessionStorage.getItem(Constants.SelectedOrderId) || '0');
      const order = await this.orderProvider.getOrderById(this.state.username, this.state.sessionKey, orderId);
      this.setState({ order: order });
    }
    catch (error) {
      this.setState({ showMengeModal: false });
      this.handleError("Fehler beim Ändern der Bestellposition", error);
    }
  }

  handleAddOrderYes = async () : Promise<void> => {
    try {
      const newOrder = await this.orderProvider.addOrder(this.state.username, this.state.sessionKey, new OrderItem());
      const orders = await this.orderProvider.getOrders(this.state.username, this.state.sessionKey);
      orders.sort((a, b) => (a.orderDate === b.orderDate) ? ((a.id > b.id) ? -1 : 1) : (a.orderDate > b.orderDate) ? -1 : 1);
      sessionStorage.setItem(Constants.SelectedOrderId, String(newOrder.id));
      this.setState({ order: newOrder, confirmationProps: null });
    }
    catch (error) {
      this.setState({ confirmationProps: null });
      this.handleError("Fehler beim Hinzufügen der Bestellung", error);
    }
  }

  handleModifyMengeOk = async (orderlistDetail: ArticlelistDetailItem): Promise<void> => {
    try {
      this.setState({ showMengeModal: false, showSaveModal: true });
      await this.saveOrderDetail(orderlistDetail);

      const articles = await this.orderlistProvider.getArticlelistDetails(this.state.username, this.state.sessionKey);

      var order = new OrderItem();
      const orderId = Number(sessionStorage.getItem(Constants.SelectedOrderId) || '0');
      if (orderId > 0) {
        order = await this.orderProvider.getOrderById(this.state.username, this.state.sessionKey, orderId);
        order.orderDetails.forEach((orderDetail: OrderDetailItem) => {
          const orderlistDetail = articles.find(d => d.article?.id === orderDetail.articleId);
          if (!!orderlistDetail) {
            if (!!orderlistDetail) {
              if (orderDetail.article.articlePortioning === true) {
                var copiedOrderlistDetail = new ArticlelistDetailItem();
                copiedOrderlistDetail.amount = orderDetail.amount;
                copiedOrderlistDetail.portioningAmount = orderDetail.portioningAmount;
                copiedOrderlistDetail.comment = orderDetail.comment;
                copiedOrderlistDetail.sortOrder = orderlistDetail.sortOrder;
                copiedOrderlistDetail.article = orderlistDetail.article;
                copiedOrderlistDetail.orderDetailItemId = orderDetail.id;
                articles.push(copiedOrderlistDetail);
              }
              else {
                orderlistDetail.amount = orderDetail.amount;
                orderlistDetail.portioningAmount = orderDetail.portioningAmount;
                orderlistDetail.comment = orderDetail.comment;
                orderlistDetail.orderDetailItemId = orderDetail.id;
              }
            }
          }
        });
      }

      articles.forEach((article: ArticlelistDetailItem) => {
        article.compoundKey = `${article.id};${article.orderDetailItemId}`;
        article.timestamp = new Date();
      });

      articles.sort((a, b) => {
        var detailIdA = (a.orderDetailItemId || 0) === 0 ? Number.MAX_SAFE_INTEGER : a.orderDetailItemId;
        var detailIdB = (b.orderDetailItemId || 0) === 0 ? Number.MAX_SAFE_INTEGER : b.orderDetailItemId;
        if (a.sortOrder === b.sortOrder) {
          return detailIdA < detailIdB ? -1 : 1;
        }
        return a.sortOrder < b.sortOrder ? -1 : 1;
      });

      this.setState({ order: order, articles: articles, showMengeModal: false, showSaveModal: false });
    }
    catch (error) {
      this.setState({ showMengeModal: false, showSaveModal: false });
      this.handleError("Fehler beim Ändern der Bestellposition", error);
    }
  }

  afterSaveCell = async (_oldValue: any, _newValue: any, row: ArticlelistDetailItem, _column: any): Promise<void> => {
    this.saveOrderDetail(row);
  };

  amountFormatter = (cell: any, _row: ArticlelistDetailItem, _rowIndex: any, _extra: any) => {
    let numericValue = Number(cell);
    return (
      <React.Fragment>
        {_row.article ? isNaN(numericValue) ? "0" : numericValue.toFixed(3) : ""}
      </React.Fragment>
    );
  }

  articleNoFormatter = (_cell: any, row: ArticlelistDetailItem, _rowIndex: any, _extra: any) => {
    return (
      <React.Fragment>
        {row.article ? row.article.no : ""}
      </React.Fragment>
    );
  }

  displayTextFormatter = (_cell: any, row: ArticlelistDetailItem, _rowIndex: any, _extra: any) => {
    return (
      <React.Fragment>
        {row.article ? (this.state.customer.showArticleSecondLanguage && row.article.nameSecondLanguage) ? row.article.nameSecondLanguage : row.article.name : <b>{row.textLine}&nbsp;</b>}
      </React.Fragment>
    );
  }

  columnEvents = {
    onClick: (_e: any, _column: any, _columnIndex: any, row: ArticlelistDetailItem, _rowIndex: any) => {
      if (row.article) {
        if (this.state.order.id !== 0 && this.state.order.state === 0) {
          this.setState({ orderlistDetail: row, showMengeModal: true });
        }
        else if (this.state.customer.switchToArticleList === true) {
          this.setState({
            confirmationProps: {
              title: "Bestellung eröffnen",
              message: "Möchten Sie eine neue Bestellung eröffnen?",
              yesHandler: async () => { this.handleAddOrderYes(); },
              noHandler: async () => { this.setState({ confirmationProps: null }); },
              show: true
            }
          });
        }
      }
    }
  }

  xsColumns = [
    { dataField: 'amount', text: 'Menge', align: 'right', formatter: this.amountFormatter, events: this.columnEvents },
    { dataField: 'article.name', text: 'Name', formatter: this.displayTextFormatter, events: this.columnEvents },
    { dataField: 'article.webArticleInfo', text: 'Info', events: this.columnEvents },
  ];

  lgColumns = [
    { dataField: 'amount', text: 'Menge', align: 'right', formatter: this.amountFormatter, events: this.columnEvents },
    { dataField: 'article.no', text: 'Nr', formatter: this.articleNoFormatter, events: this.columnEvents },
    { dataField: 'article.name', text: 'Name', formatter: this.displayTextFormatter, events: this.columnEvents },
    { dataField: 'article.webArticleInfo', text: 'Info', events: this.columnEvents },
  ];

  render() {
    if (this.state.username === "" || this.state.sessionKey === "") {
      return (<Redirect to="/" />);
    }

    if (!this.state.isSessionValid) {
      return (
        <CustomAlert
          title={Constants.SessionExpiredTitle}
          error={Constants.SessionExpiredMessage}
          closeHandler={async () => {
            sessionStorage.removeItem(Constants.Username);
            sessionStorage.removeItem(Constants.SessionKey);
            this.setState({
              username: "",
              sessionKey: "",
            });
          }}
          show={true}
        />
      )
    }

    if (this.state.customer.isWebAdmin) {
      return (
        <CustomAlert
          title={Constants.AdminPageNotAvailableTitle}
          error={Constants.AdminPageNotAvailableMessage}
          show={true}
        />
      )
    }

    return (
      <div>
        <Container className="p-0">
          <CustomAlert
            {...this.state.alertProps}
          />
          <Row>
            <Col>
              <CustomerDetail customer={this.state.customer} />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <OrderDetailHeader order={this.state.order} />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <div className="d-block d-lg-none">
                <ToolkitProvider keyField='compoundKey' data={this.state.articles} columns={this.xsColumns} search={{onColumnMatch}}>
                  {props => (
                    <React.Fragment>
                      <div className="d-flex justify-content-between">
                        <h3>Artikel</h3>
                        <SearchBar {...props.searchProps} placeholder="Artikel suchen..." />
                      </div>
                      <BootstrapTable {...props.baseProps} hover classes="table-sm w-auto" />
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
              <div className="d-none d-lg-block">
                <ToolkitProvider keyField='compoundKey' data={this.state.articles} columns={this.lgColumns} search={{onColumnMatch}}>
                  {props => (
                    <React.Fragment>
                      <div className="d-flex justify-content-between">
                        <h3>Artikel</h3>
                        <SearchBar {...props.searchProps} placeholder="Artikel suchen..." />
                      </div>
                      <BootstrapTable {...props.baseProps} hover classes="table-sm w-auto" />
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </Col>
          </Row>
        </Container >
        {
          this.state.showMengeModal && <OrderlistDetailModal
            orderlistDetail={this.state.orderlistDetail}
            okHandler={(orderlistDetail: ArticlelistDetailItem) => { this.handleModifyMengeOk(orderlistDetail) }}
            cancelHandler={() => { this.setState({ showMengeModal: false }) }}
          />
        }
        {
          this.state.showSaveModal && <Modal show={true} animation={false} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Daten speichern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center">
                <Spinner as="span" animation="border" variant="success" role="status" />
                <span className="ml-4" style={{ fontSize: "large" }}>Daten speichern...</span>
              </div>
            </Modal.Body>
          </Modal>
        }
        <ConfirmationModal
          {...this.state.confirmationProps}
        />
      </div >
    );
  }
}
