export const Username = 'username';
export const SessionKey = 'sessionkey';
export const IsSessionValid = 'sessionvalid';
export const SelectedOrderId = 'selectedorderid';
export const Customer = 'customer';
export const SessionExpiredTitle = 'Ihre Sitzung ist abgelaufen.';
export const SessionExpiredMessage = 'Bitte melden Sie sich erneut an.';
export const SwitchToArticleList = 'switchToArticleList';
export const AdminPageNotAvailableTitle = 'Seite nicht verfügbar.';
export const AdminPageAvailableMessage = 'Diese Seite ist nur im Admin-Modus verfügbar.';
export const AdminPageNotAvailableMessage = 'Diese Seite ist im Admin-Modus nicht verfügbar.';
export const MaxFileDropSizeInKb = 64;
export const VersionInfo = 'Version 1.4.6';
