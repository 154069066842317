import * as Axios from 'axios';

import { CustomerItem } from '../providers/Models';

export class CustomerProvider {

  async getCurrent(username: string, sessionKey: string): Promise<CustomerItem> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/customer/GetCurrent?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'GET',
      headers: { Accept: 'application/json; odata=verbose' }
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }
}
