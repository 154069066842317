import React from 'react';
import { BagPlus, Camera } from 'react-bootstrap-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import * as Rx from "rxjs";

import { ArticleItem, ArticlePhotoItem, OrderItem } from '../../providers/Models';
import ReactTooltip from 'react-tooltip';

const { SearchBar } = Search;

interface Props {
  articles: ArticleItem[],
  addArticleHandler: (articleId: number) => void,
  showArticlePhotoHandler: (articlePhotos: ArticlePhotoItem[]) => void,
  subject: Rx.BehaviorSubject<OrderItem>
}

interface State {
  orderState: number,
  order: OrderItem,
  showArticleSecondLanguage: boolean,
  columns: any[]
}

interface ColumnMatchProps {
  searchText: string;
  value: any;
  column: {
    dataField: string;
    text: string;
  };
  row: ArticleItem;
}

function onColumnMatch({ searchText, value, column, row }: ColumnMatchProps): boolean {
  const searchValue = (searchText ?? "").toLocaleLowerCase();

  if (column.dataField === 'name') {
    const nameValue = (row.name ?? "").toLocaleLowerCase();
    const nameSecondLanguageValue = (row.nameSecondLanguage ?? "").toLocaleLowerCase();
    return nameValue.includes(searchValue) || nameSecondLanguageValue.includes(searchValue);
  }

  const stringValue = (value?.toString() ?? "").toLocaleLowerCase();
  return stringValue.includes(searchValue);
}

export class ArticleList extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { orderState: 0, order: new OrderItem(), showArticleSecondLanguage: false, columns: [{ dataField: "", text: "" }] };
  }

  componentDidMount() {
    this.props.subject.subscribe((data) => {
      this.setState({
        orderState: data.state,
        showArticleSecondLanguage: data.customer.showArticleSecondLanguage,
        columns: data.customer.showArticlePrice ?
          [
            { dataField: 'no', text: 'Nr' },
            { dataField: 'name', text: 'Artikel', formatter: this.displayTextFormatter },
            { dataField: 'price', text: 'Preis', align: 'right', formatter: this.priceFormatter },
            { dataField: 'unit', text: 'Einheit' },
            { dataField: '', text: '', isDummyField: true, headerStyle: () => { return { width: "45px" }; }, formatExtraData: data.state, formatter: this.actionFormatter }
          ]
          : [
            { dataField: 'no', text: 'Nr' },
            { dataField: 'name', text: 'Artikel', formatter: this.displayTextFormatter },
            { dataField: 'unit', text: 'Einheit' },
            { dataField: '', text: '', isDummyField: true, headerStyle: () => { return { width: "45px" }; }, formatExtraData: data.state, formatter: this.actionFormatter }
          ]
      });
    });
  }

  componentWillUnmount() {
    this.props.subject.unsubscribe();
  }

  priceFormatter = (cell: any, _row: ArticleItem, _rowIndex: any, _extra: any) => {
    return (
      <React.Fragment>
        {isNaN(cell) ? "" : cell.toFixed(2)}
      </React.Fragment>
    );
  }

  displayTextFormatter = (_cell: any, row: ArticleItem, _rowIndex: any, _extra: any) => {
    return (
      <React.Fragment>
        {(this.state.showArticleSecondLanguage && row.nameSecondLanguage) ? row.nameSecondLanguage : row.name}
      </React.Fragment>
    );
  }

  actionFormatter = (_cell: any, row: ArticleItem, _rowIndex: any, extra: any) => {
    if (extra === 0) {
      return (
        <div className="d-flex justify-content-center">
          <div data-tip data-for="addArticleTip" style={{ cursor: "pointer" }}><BagPlus onClick={() => this.props.addArticleHandler(row.id)} /></div>
          <ReactTooltip id="addArticleTip" place="top" effect="solid">Artikel hinzufügen</ReactTooltip>
          {row.articlePhotos && row.articlePhotos.length > 0 &&
            <React.Fragment>
              <div data-tip data-for="showArticlePhotoTip" className="ml-2" style={{ cursor: "pointer" }}><Camera onClick={() => this.props.showArticlePhotoHandler(row.articlePhotos)} /></div>
              <ReactTooltip id="showArticlePhotoTip" place="top" effect="solid">Artikelfoto anzeigen</ReactTooltip>
            </React.Fragment>}
        </div>
      );
    }
    else {
      return (
        <div />
      );
    }
  };

  render() {
    return (
      <ToolkitProvider
        keyField="id"
        data={this.props.articles}
        columns={this.state.columns}
        search={ {
          onColumnMatch
        } }
      >
        {props => (
          <React.Fragment>
            <div className="d-flex justify-content-between" style={{ height: "2.8em" }}>
              <h3>Artikel</h3>
              <SearchBar {...props.searchProps} placeholder="Artikel suchen..." />
            </div>
            <BootstrapTable
              {...props.baseProps}
              hover
              classes="table-sm w-auto"
              pagination={paginationFactory({ sizePerPage: 5, hidePageListOnlyOnePage: true, hideSizePerPage: true })}
            />
          </React.Fragment>
        )}
      </ToolkitProvider>
    );
  }
}
