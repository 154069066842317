import React from 'react';
import { BagCheck, BagPlus, Files, Pencil, Trash } from 'react-bootstrap-icons';
import BootstrapTable, { SelectRowProps } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import * as Rx from "rxjs";
import moment from 'moment';

import { OrderItem, OrderState } from '../../providers/Models';
import { DragAndDrop } from '../Common/DragAndDrop';
import ReactTooltip from 'react-tooltip';

interface Props {
  selectOrderHandler: (orderId: number) => void,
  addOrderHandler: () => void,
  modifyOrderHandler: (orderId: number) => void,
  saveOrderHandler: (orderId: number) => void,
  removeOrderHandler: (orderId: number) => void,
  copyOrderHandler: (orderId: number) => void,
  dropFileHandler: (files: any) => void,
  orderListSubject: Rx.BehaviorSubject<OrderItem[]>,
  selectionSubject: Rx.BehaviorSubject<number>,
}

interface State {
  orders: OrderItem[],
  selected: number[],
}

export class OrderList extends React.Component<Props, State> {

  node: any;

  constructor(props: Props) {
    super(props);
    this.state = { orders: [], selected: [] };
  }

  componentDidMount() {
    this.props.orderListSubject.subscribe((data) => {
      this.setState({ orders: data });
    });
    this.props.selectionSubject.subscribe((data) => {
      this.setState({ selected: [data] });
      const index = this.state.orders.findIndex(o => o.id === data);
      const indexPage = Math.ceil(index / this.node.paginationContext.currSizePerPage);
      if (index > -1 && indexPage !== this.node.paginationContext.currPage) {
        this.node.paginationContext.currPage = indexPage;
      }
    });
  }

  componentWillUnmount() {
    this.props.orderListSubject.unsubscribe();
    this.props.selectionSubject.unsubscribe();
  }

  actionFormatter = (_cell: any, row: OrderItem, _rowIndex: any, _extra: any) => {
    if (row.state === 0) {
      return (
        <div className="d-flex justify-content-center">
          <div data-tip data-for="modifyOrderTip" style={{ cursor: "pointer" }}><Pencil onClick={() => this.props.modifyOrderHandler(row.id)} /></div>
          <div data-tip data-for="saveOrderTip" className="ml-2" style={{ cursor: "pointer" }}><BagCheck onClick={() => this.props.saveOrderHandler(row.id)} /></div>
          <div data-tip data-for="removeOrderTip" className="ml-2" style={{ cursor: "pointer" }}><Trash onClick={() => this.props.removeOrderHandler(row.id)} /></div>
          <div data-tip data-for="copyOrderTip" className="ml-2" style={{ cursor: "pointer" }}><Files onClick={() => this.props.copyOrderHandler(row.id)} /></div>
          <ReactTooltip id="modifyOrderTip" place="top" effect="solid">Bestellung bearbeiten</ReactTooltip>
          <ReactTooltip id="saveOrderTip" place="top" effect="solid">Bestellung abschliessen</ReactTooltip>
          <ReactTooltip id="removeOrderTip" place="top" effect="solid">Bestellung löschen</ReactTooltip>
          <ReactTooltip id="copyOrderTip" place="top" effect="solid">Bestellung kopieren</ReactTooltip>
        </div>
      );
    }
    else {
      return (
        <div className="d-flex justify-content-center">
          <div data-tip data-for="copyOrderTip" style={{ cursor: "pointer" }}><Files onClick={() => this.props.copyOrderHandler(row.id)} /></div>
          <ReactTooltip id="copyOrderTip" place="top" effect="solid">Bestellung kopieren</ReactTooltip>
        </div>
      );
    }
  };

  headerFormatter = (_column: any, _columnIndex: any, _components: any) => {
    return (
      <div className="d-flex justify-content-center">
        <div data-tip data-for="newOrderTip" style={{ cursor: "pointer" }}><BagPlus onClick={() => this.props.addOrderHandler()} /></div>
        <ReactTooltip id="newOrderTip" place="top" effect="solid">Neue Bestellung erfassen</ReactTooltip>
      </div>
    );
  };

  dateFormatter = (cell: any, _row: any) => {
    return (
      <React.Fragment>
        { !!cell ? moment(cell).format('DD.MM.YYYY') : ""}
      </React.Fragment>
    );
  }

  stateFormatter = (cell: any, _row: any) => {
    return (
      <React.Fragment>
        { !!OrderState[cell] ? OrderState[cell] : "-"}
      </React.Fragment>
    );
  }

  columnEvents = {
    onClick: (_e: any, _column: any, _columnIndex: any, row: OrderItem, _rowIndex: any) => {
      this.props.selectOrderHandler(row.id);
    }
  }

  columns = [
    { dataField: 'orderDate', text: 'Bestelldatum', formatter: this.dateFormatter, events: this.columnEvents },
    { dataField: 'deliveryDate', text: 'Lieferdatum', headerClasses: 'd-none d-md-table-cell', classes: 'd-none d-md-table-cell', formatter: this.dateFormatter, events: this.columnEvents },
    { dataField: 'comment', text: 'Bemerkung', headerClasses: 'd-none d-md-table-cell', classes: 'd-none d-md-table-cell', events: this.columnEvents },
    { dataField: 'state', text: 'Status', formatter: this.stateFormatter, events: this.columnEvents },
    { dataField: '', text: '', isDummyField: true, headerStyle: () => { return { width: "110px" }; }, formatter: this.actionFormatter, headerFormatter: this.headerFormatter }
  ];

  render() {

    const selectRow: SelectRowProps<any> = {
      mode: 'radio',
      hideSelectColumn: true,
      clickToSelect: false,
      selected: this.state.selected,
      style: { background: 'lightgray' }
    };

    return (
      <DragAndDrop dropHandler={this.props.dropFileHandler}>
        <BootstrapTable
          hover
          classes="table-sm w-auto"
          keyField='id'
          data={this.state.orders}
          columns={this.columns}
          selectRow={selectRow}
          pagination={paginationFactory({ sizePerPage: 5, hidePageListOnlyOnePage: true, hideSizePerPage: true })}
          ref={(n) => (this.node = n)}
        />
      </DragAndDrop>
    );
  }
}
