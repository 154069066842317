import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Col, Container, Row } from 'react-bootstrap';

import { ArticlelistDetailItem } from '../../providers/Models';

interface Props {
  orderlistDetail: ArticlelistDetailItem,
  okHandler: (orderlistDetail: ArticlelistDetailItem) => void,
  cancelHandler: () => void,
}

interface State {
  menge: number,
  isMengeValid: boolean,
  portionierung: boolean,
  portionierungMenge: number,
  isPortionierungMengeValid: boolean,
  bemerkung: string,
  showNumericPad: boolean,
}

export class OrderlistDetailModal extends React.Component<Props, State> {
  mengeInput: React.RefObject<HTMLInputElement>;
  portionierungMengeInput: React.RefObject<HTMLInputElement>;
  submitButton: React.RefObject<HTMLButtonElement>;
  keyList = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "C", "0", "."];
  isMengeFocused: boolean;
  isPortionierungMengeFocused: boolean;

  constructor(props: Props) {
    super(props);
    this.state = {
      menge: this.props.orderlistDetail.amount,
      isMengeValid: this.validateMenge(this.props.orderlistDetail.amount),
      portionierung: this.props.orderlistDetail.article?.articlePortioning || false,
      portionierungMenge: this.props.orderlistDetail.portioningAmount || 0,
      isPortionierungMengeValid: this.validatePortionierungMenge(this.props.orderlistDetail.portioningAmount || 0),
      bemerkung: this.props.orderlistDetail.comment,
      showNumericPad: true,
    };
    this.mengeInput = React.createRef();
    this.portionierungMengeInput = React.createRef();
    this.submitButton = React.createRef();
    this.isMengeFocused = true;
    this.isPortionierungMengeFocused = false;
  }

  componentDidMount() {
    const setKey = (event: any) => {
      const keyValue = event.key.toUpperCase();
      if (keyValue === "ENTER") {
        event.preventDefault();
        this.submitButton.current?.click();
      }
      else if (this.keyList.includes(keyValue)) {
        this.handleNumberClick(keyValue);
      }
    }
    document.addEventListener('keydown', setKey);
  }

  validateMenge(value: number): boolean {
    if ((this.props.orderlistDetail.orderDetailItemId || 0) === 0) {
      return (!isNaN(value) && value > 0);
    }
    else {
      return (!isNaN(value) && value >= 0);
    }
  }

  validatePortionierungMenge(value: number): boolean {
    return (!isNaN(value) && value >= 0);
  }

  handleBemerkungChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ bemerkung: event.currentTarget.value });
  }

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!this.state.isMengeValid || !this.state.isPortionierungMengeValid) {
      return false;
    }

    const orderlistDetail = this.props.orderlistDetail;
    orderlistDetail.amount = this.state.menge;
    orderlistDetail.portioningAmount = this.state.portionierungMenge;
    orderlistDetail.comment = this.state.bemerkung;
    this.props.okHandler(orderlistDetail);
  }

  handleNumberClick = (value: string) => {
    if (this.isMengeFocused) {
      if (this.mengeInput.current === null) {
        return;
      }

      var mengeTextValue = value !== "C" ? this.mengeInput.current.value : "";
      if (value !== "C") {
        mengeTextValue += value;
      }

      this.mengeInput.current.value = mengeTextValue;
      const numberValue = Number(mengeTextValue);
      this.setState({ menge: numberValue, isMengeValid: this.validateMenge(numberValue) });
    }

    else if (this.isPortionierungMengeFocused) {
      if (this.portionierungMengeInput.current === null) {
        return;
      }

      var portionierungMengeTextValue = value !== "C" ? this.portionierungMengeInput.current.value : "";
      if (value !== "C") {
        portionierungMengeTextValue += value;
      }

      this.portionierungMengeInput.current.value = portionierungMengeTextValue;
      const numberValue = Number(portionierungMengeTextValue);
      this.setState({ portionierungMenge: numberValue, isPortionierungMengeValid: this.validatePortionierungMenge(numberValue) });
    }
  }

  render() {

    const buttonStyle = { fontSize: "xx-large", width: "1.9em", height: "1.9em" };
    const buttonClass = "mr-3";
    const unitInfo = this.props.orderlistDetail.article?.webArticleInfo !== null ? `(${this.props.orderlistDetail.article?.webArticleInfo})` : "";

    return (
      <Modal show={true} onHide={() => this.props.cancelHandler()} animation={false} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>Menge bearbeiten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Container className="p-0">
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{`${this.props.orderlistDetail.article?.name} ${unitInfo}`}</Form.Label>
                    <Form.Control
                      className={!this.state.isMengeValid ? "is-invalid" : ""}
                      readOnly={true}
                      defaultValue={this.state.menge || 0}
                      ref={this.mengeInput}
                      onFocus={() => { this.isMengeFocused = true; this.isPortionierungMengeFocused = false; this.setState({ showNumericPad: true }); }}
                    />
                    {
                      this.state.portionierung && <React.Fragment>
                        <Form.Label className="mt-2">Portionsgrösse</Form.Label>
                        <Form.Control
                          className={!this.state.isPortionierungMengeValid ? "is-invalid" : ""}
                          readOnly={true}
                          defaultValue={this.state.portionierungMenge || 0}
                          ref={this.portionierungMengeInput}
                          onFocus={() => { this.isMengeFocused = false; this.isPortionierungMengeFocused = true; this.setState({ showNumericPad: true }); }}
                        />
                      </React.Fragment>
                    }
                    <Form.Label className="mt-2">Bemerkung</Form.Label>
                    <Form.Control
                      as="textarea"
                      readOnly={this.state.showNumericPad}
                      defaultValue={this.state.bemerkung}
                      onFocus={() => { this.isMengeFocused = false; this.isPortionierungMengeFocused = false; this.setState({ showNumericPad: false }); }}
                      onChange={this.handleBemerkungChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {
                this.state.showNumericPad && <Row className="text-center">
                  <Col>
                    <Row>
                      <Col>
                        <Button className={buttonClass} style={buttonStyle} onClick={() => this.handleNumberClick("7")}>7</Button>
                        <Button className={buttonClass} style={buttonStyle} onClick={() => this.handleNumberClick("8")}>8</Button>
                        <Button style={buttonStyle} onClick={() => this.handleNumberClick("9")}>9</Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3">
                        <Button className={buttonClass} style={buttonStyle} onClick={() => this.handleNumberClick("4")}>4</Button>
                        <Button className={buttonClass} style={buttonStyle} onClick={() => this.handleNumberClick("5")}>5</Button>
                        <Button style={buttonStyle} onClick={() => this.handleNumberClick("6")}>6</Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3">
                        <Button className={buttonClass} style={buttonStyle} onClick={() => this.handleNumberClick("1")}>1</Button>
                        <Button className={buttonClass} style={buttonStyle} onClick={() => this.handleNumberClick("2")}>2</Button>
                        <Button style={buttonStyle} onClick={() => this.handleNumberClick("3")}>3</Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3">
                        <Button className={buttonClass} style={buttonStyle} onClick={() => this.handleNumberClick("C")}>C</Button>
                        <Button className={buttonClass} style={buttonStyle} onClick={() => this.handleNumberClick("0")}>0</Button>
                        <Button style={buttonStyle} onClick={() => this.handleNumberClick(".")}>.</Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-3">
                        <Button style={{ fontSize: "xx-large", height: "1.9em" }} type="submit" ref={this.submitButton}>&Uuml;bernehmen</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}
