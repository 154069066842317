import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import * as Rx from "rxjs";

import { PromotionItem } from '../../providers/Models';
import { PromotionArticleList } from './PromotionArticleList';

interface Props {
  subject: Rx.BehaviorSubject<PromotionItem>
}

interface State {
  promotion: PromotionItem
}

export class PromotionDetail extends React.Component<Props, State> {

  subject = new Rx.BehaviorSubject<PromotionItem>(new PromotionItem());

  constructor(props: Props) {
    super(props);
    this.state = { promotion: new PromotionItem() };
  }

  componentDidMount() {
    this.props.subject.subscribe((data) => {
      this.subject.next(data);
      this.setState({ promotion: data });
    });
  }

  componentWillUnmount() {
    this.props.subject.unsubscribe();
  }

  render() {
    return (
      <React.Fragment>
        <p className={this.state.promotion.id !== 0 ? "d-none" : ""}>Keine Aktion gewählt.</p>
        <Container className={this.state.promotion.id === 0 ? "d-none" : "p-0"}>
          <Row>
            <Col>
              <PromotionArticleList
                subject={this.subject} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
