import moment from 'moment';
import React from 'react';

import { OrderItem } from '../../providers/Models';

export const OrderDetailHeader: React.FC<{ order: OrderItem }> = (props) => {
    return (
        <React.Fragment>
            <h2>Bestellung Details</h2>
            {props.order.id === 0
            ? <p>Keine Bestellung gewählt</p>
            : <React.Fragment>
                <p>Bestelldatum: {moment(props.order.orderDate).format('DD.MM.YYYY')}</p>
            </React.Fragment>}
        </React.Fragment>
    );
}
