import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import * as Rx from "rxjs";
import moment from 'moment';

import { OrderItem } from '../../providers/Models';

interface Props {
  okHandler: () => void,
  cancelHandler: () => void,
  show: boolean,
  subject: Rx.BehaviorSubject<OrderItem>
}

interface State {
  lieferdatum: Date,
  isLieferdatumValid: boolean,
  bemerkung: string,
}

export class OrderModal extends React.Component<Props, State> {
  lieferdatumInput: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = { lieferdatum: moment().toDate(), isLieferdatumValid: true, bemerkung: '' };
    this.lieferdatumInput = React.createRef();
  }

  componentDidMount() {
    this.props.subject.subscribe((data) => {
      this.setState({
        lieferdatum: data.deliveryDate,
        isLieferdatumValid: this.validateLieferdatum(moment(data.deliveryDate)),
        bemerkung: data.comment,
      });
    });
  }

  componentWillUnmount() {
    this.props.subject.unsubscribe();
  }

  validateLieferdatum(value: moment.Moment): boolean {
    const minDate = moment().startOf('day');
    const maxDate = moment().add(20, 'days').startOf('day');
    return (value.isValid() && value.isSameOrAfter(minDate) && value.isSameOrBefore(maxDate));
  }

  handleLieferdatumChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const dateValue = moment(event.currentTarget.value).startOf('day');
    if (this.validateLieferdatum(dateValue)) {
      this.setState({ lieferdatum: dateValue.toDate(), isLieferdatumValid: true });
    }
    else {
      this.setState({ isLieferdatumValid: false });
    }
  }

  handleBemerkungChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ bemerkung: event.currentTarget.value });
  }

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!this.state.isLieferdatumValid) {
      return false;
    }

    const order = this.props.subject.value;
    order.deliveryDate = this.state.lieferdatum;
    order.comment = this.state.bemerkung;

    this.props.subject.next(order);
    this.props.okHandler();
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={() => this.props.cancelHandler()} onShow={(): void => { this.lieferdatumInput.current?.focus(); this.lieferdatumInput.current?.select(); }} animation={false} backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>Bestellung bearbeiten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Label>Lieferdatum</Form.Label>
              <Form.Control type="date" className={!this.state.isLieferdatumValid ? "is-invalid" : ""} defaultValue={moment(this.state.lieferdatum).format('YYYY-MM-DD')} onChange={this.handleLieferdatumChange} ref={this.lieferdatumInput} />
              <Form.Label>Bemerkung</Form.Label>
              <Form.Control as="textarea" rows={4} defaultValue={this.state.bemerkung} onChange={this.handleBemerkungChange} />
            </Form.Group>
            <Button variant="primary" type="submit">&Uuml;bernehmen</Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}
