import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import * as Rx from "rxjs";

import { OrderItem, ArticleItem, ArticlePhotoItem } from '../../providers/Models';
import { OrderDetailHeader } from '../Order/OrderDetailHeader';
import { OrderArticleList } from '../Order/OrderArticleList';
import { ArticleList } from '../Article/ArticleList';

interface Props {
  articles: ArticleItem[],
  addArticleHandler: (articleId: number) => void,
  showArticlePhotoHandler: (articlePhotos: ArticlePhotoItem[]) => void,
  modifyArticleHandler: (orderDetailId: number) => void,
  removeArticleHandler: (orderDetailId: number) => void,
  subject: Rx.BehaviorSubject<OrderItem>
}

interface State {
  order: OrderItem
}

export class OrderDetail extends React.Component<Props, State> {

  subject = new Rx.BehaviorSubject<OrderItem>(new OrderItem());

  constructor(props: Props) {
    super(props);
    this.state = { order: new OrderItem() };
  }

  componentDidMount() {
    this.props.subject.subscribe((data) => {
      this.subject.next(data);
      this.setState({ order: data });
    });
  }

  componentWillUnmount() {
    this.props.subject.unsubscribe();
  }

  render() {
    return (
      <React.Fragment>
        <p className={this.state.order.id !== 0 ? "d-none" : ""}>Keine Bestellung gewählt.</p>
        <Container className={this.state.order.id === 0 ? "d-none" : "p-0"}>
          <Row>
            <Col className="col-12">
              <OrderDetailHeader order={this.state.order} />
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-lg-6">
              <OrderArticleList
                modifyArticleHandler={this.props.modifyArticleHandler}
                removeArticleHandler={this.props.removeArticleHandler}
                subject={this.subject} />
            </Col>
            <Col className="col-12 col-lg-6">
              <ArticleList
                articles={this.props.articles}
                addArticleHandler={this.props.addArticleHandler}
                showArticlePhotoHandler={this.props.showArticlePhotoHandler}
                subject={this.subject} />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
