import * as Axios from 'axios';

import { OrderDetailItem, OrderItem } from '../providers/Models';

export class OrderProvider {

  async getOrders(username: string, sessionKey: string): Promise<OrderItem[]> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/order?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'GET',
      headers: { Accept: 'application/json; odata=verbose' }
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }

  async getOrdersAdmin(username: string, sessionKey: string): Promise<OrderItem[]> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/orderadmin?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'GET',
      headers: { Accept: 'application/json; odata=verbose' }
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }

  async getOrderById(username: string, sessionKey: string, orderId: number): Promise<OrderItem> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/order/${orderId}?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'GET',
      headers: { Accept: 'application/json; odata=verbose' }
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }

  async addOrder(username: string, sessionKey: string, order: OrderItem): Promise<OrderItem> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/order?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'POST',
      headers: { Accept: 'application/json; odata=verbose', ContentType: 'application/json' },
      data: order
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }

  async modifyOrder(username: string, sessionKey: string, order: OrderItem): Promise<OrderItem> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/order/${order.id}?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'PUT',
      headers: { Accept: 'application/json; odata=verbose', ContentType: 'application/json' },
      data: order
    }
    await Axios.default(axiosConfig);
    const modifiedOrder = await this.getOrderById(username, sessionKey, order.id);
    return modifiedOrder;
  }

  async removeOrder(username: string, sessionKey: string, orderId: number): Promise<OrderItem> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/order/${orderId}?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'DELETE',
      headers: { Accept: 'application/json; odata=verbose', ContentType: 'application/json' }
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }

  async copyOrder(username: string, sessionKey: string, orderId: number): Promise<OrderItem> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/order?username=${username || ''}&sessionkey=${sessionKey || ''}&sourceId=${orderId}`,
      method: 'POST',
      headers: { Accept: 'application/json; odata=verbose', ContentType: 'application/json' },
      data: new OrderItem()
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }

  async addOrderArticle(username: string, sessionKey: string, orderDetail: OrderDetailItem): Promise<OrderDetailItem> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/orderdetail?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'POST',
      headers: { Accept: 'application/json; odata=verbose', ContentType: 'application/json' },
      data: orderDetail
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }

  async modifyOrderArticle(username: string, sessionKey: string, orderDetail: OrderDetailItem): Promise<OrderDetailItem> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/orderdetail/${orderDetail.id}?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'PUT',
      headers: { Accept: 'application/json; odata=verbose', ContentType: 'application/json' },
      data: orderDetail
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }

  async removeOrderArticle(username: string, sessionKey: string, orderDetailId: number): Promise<OrderDetailItem> {
    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/orderdetail/${orderDetailId}?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'DELETE',
      headers: { Accept: 'application/json; odata=verbose', ContentType: 'application/json' }
    }
    const response = await Axios.default(axiosConfig);
    return response.data;
  }

  async uploadBarcodeFile(username: string, sessionKey: string, file: any): Promise<OrderItem> {

    const formData = new FormData();
    formData.append('file',file);

    let axiosConfig: Axios.AxiosRequestConfig = {
      url: `${process.env.REACT_APP_API_URL}/orderupload?username=${username || ''}&sessionkey=${sessionKey || ''}`,
      method: 'POST',
      headers: { Accept: 'application/json; odata=verbose', ContentType: 'multipart/form-data' },
      data: formData
    }
    const response = await Axios.default(axiosConfig);

    return response.data;
  }
}
