import React from 'react';
import { Alert } from 'react-bootstrap';

export interface ICustomAlertProps {
  title?: string | null | undefined,
  error?: any,
  closeHandler?: () => Promise<void> | null | undefined,
  show?: boolean | null | undefined,
}

interface State {
}

export class CustomAlert extends React.Component<ICustomAlertProps, State> {
  render() {
    let messageText = '';
    if (this.props.error !== undefined) {
      if (typeof(this.props.error) === 'string') {
        messageText = this.props.error;
      }
      else if (this.props.error.response !== undefined) {
        messageText = this.props.error.response.data;
      }
      else if ('message' in this.props.error) {
        messageText = `${this.props.error['message']}`
      }
    }

    return (
      <Alert
        show={!!this.props.show}
        variant="danger"
        onClose={() => { if (this.props.closeHandler) this.props.closeHandler() }}
        dismissible
      >
        {this.props.title && <Alert.Heading>{this.props.title || ''}</Alert.Heading>}
        {messageText.split('\n').map((str, index) => <p key={index} className="m-0">{str}</p>)}
      </Alert>
    );
  }
}
