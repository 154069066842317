import React from 'react';
import { Redirect } from 'react-router';
import { Col, Container, Row } from 'react-bootstrap';

import * as Rx from "rxjs";

import { CustomerProvider } from '../../providers/CustomerProvider';
import { PromotionProvider } from '../../providers/PromotionProvider';

import { CustomerItem, PromotionItem } from '../../providers/Models';
import { CustomAlert, ICustomAlertProps } from '../Common/CustomAlert';
import { CustomerDetail } from '../Customer/CustomerDetail';
import { PromotionList } from '../Promotion/PromotionList';
import { PromotionDetail } from '../Promotion/PromotionDetail';

import * as Constants from '../Constants';

interface Props { }

interface State {
  username: string,
  sessionKey: string,
  isSessionValid: boolean,
  customer: CustomerItem,
  promotions: PromotionItem[],
  alertProps: ICustomAlertProps | null | undefined,
}

export class CustomerPromotions extends React.Component<Props, State> {

  customerProvider: CustomerProvider;
  promotionProvider: PromotionProvider;

  promotionSubject = new Rx.BehaviorSubject<PromotionItem>(new PromotionItem());
  selectionSubject = new Rx.BehaviorSubject<number>(0);

  constructor(props: Props) {
    super(props);
    this.customerProvider = new CustomerProvider();
    this.promotionProvider = new PromotionProvider();

    const customer: CustomerItem = JSON.parse(sessionStorage.getItem(Constants.Customer) || '{}');

    this.state = {
      username: sessionStorage.getItem(Constants.Username) || '',
      sessionKey: sessionStorage.getItem(Constants.SessionKey) || '',
      isSessionValid: Boolean(sessionStorage.getItem(Constants.IsSessionValid) || ''),
      customer: customer,
      promotions: [],
      alertProps: null,
    };
  }

  componentDidMount() {
    if (!this.state.isSessionValid)
      return;

    this.loadData();
  }

  handleError = async (title: string, error: any): Promise<void> => {
    if (error.response !== null && error.response !== undefined && error.response.status === 401) {
      sessionStorage.removeItem(Constants.IsSessionValid);
      this.setState({ isSessionValid: false });
    }
    else {
      this.setState({
        alertProps: {
          title: title,
          error: error,
          closeHandler: async () => { this.setState({ alertProps: null }); },
          show: true,
        }
      });
    }
  }

  loadData = async (): Promise<void> => {
    try {
      const validOn = new Date();
      const promotions = await this.promotionProvider.getPromotions(this.state.username, this.state.sessionKey, validOn);
      promotions.sort((a, b) => (a.validFrom < b.validFrom) ? -1 : 1);
      this.setState({ promotions: promotions });
    }
    catch (error) {
      this.handleError("Fehler beim Laden der Daten", error);
    }
  }

  handleSelectPromotion = async (promotionId: number): Promise<void> => {
    try {
      const promotion = await this.promotionProvider.getPromotionById(this.state.username, this.state.sessionKey, promotionId);
      this.promotionSubject.next(promotion);
      this.selectionSubject.next(promotionId);
    }
    catch (error) {
      this.handleError("Fehler beim Auswählen der Aktion", error);
    }
  }

  render() {
    if (this.state.username === "" || this.state.sessionKey === "") {
      return (<Redirect to="/" />);
    }

    if (!this.state.isSessionValid) {
      return (
        <CustomAlert
          title={Constants.SessionExpiredTitle}
          error={Constants.SessionExpiredMessage}
          closeHandler={async () => {
            sessionStorage.removeItem(Constants.Username);
            sessionStorage.removeItem(Constants.SessionKey);
            this.setState({
              username: "",
              sessionKey: "",
            });
          }}
          show={true}
        />
      )
    }

    if (this.state.customer.isWebAdmin) {
      return (
        <CustomAlert
          title={Constants.AdminPageNotAvailableTitle}
          error={Constants.AdminPageNotAvailableMessage}
          show={true}
        />
      )
    }

    return (
      <div>
        <Container className="p-0">
          <CustomAlert
            {...this.state.alertProps}
          />
          <Row>
            <Col>
              <CustomerDetail customer={this.state.customer} />
            </Col>
          </Row>
          <Row>
            <Col>
              <PromotionList
                promotions={this.state.promotions}
                selectPromotionHandler={this.handleSelectPromotion}
                subject={this.selectionSubject}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PromotionDetail
                subject={this.promotionSubject}
              />
            </Col>
          </Row>
        </Container>
      </div >
    );
  }
}
